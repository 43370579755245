tbody td {
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.2s linear;
    transition-delay: 0.3s, 0s;
    padding: 8px 12px 8px 12px;
}


td {
    transform-origin: center left;
    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
    padding: 8px 12px 8px 12px;
}

table {
    width: 100% !important;
    /* margin: 0 5%; */
    text-align: left;
    border-radius: 8px;
}


th,
td {
    padding: 6px 10px 6px 10px;

}

.leftTd {
    color: #868695;
    font-weight: 400;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
}

.rightTd {
    font-size: 14px;
    line-height: 20px;
    color: rgb(36, 36, 36) !important;
    text-align: end;
}

.product-params__caption {
    font-weight: 600;
    text-align: left;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 20px;
    color: rgb(36, 36, 36);
}

.dotsImg {
    height: 100% !important;
    object-fit: cover !important;
}

.dotsImgParent {
    height: calc(100% - 50%) !important;
}

.imgSlider {
    height: calc(100% - 30%) !important;
}