.section {
    margin-bottom: 250px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.productImg {
    width: 230px;
    height: 320px;
    object-fit: cover;
    /* object-position: center; */
}



.productsContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.product {
    display: flex;
    flex-direction: column;
    margin: 10px;
    cursor: pointer;
}

.titleProducts {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 40px;
    color: rgb(38, 27, 4);
}

.productTitle {
    margin-top: 5px;
    font-size: 17px;
    color: rgb(69, 59, 46);
    opacity: 0.9;
    font-weight: 500;
}

.productTitle {
    max-width: 230px;
}

.productType {
    font-size: 17px;
    color: rgb(69, 59, 46);
    opacity: 0.9;
    font-weight: 500;
}

.productPrice {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 17px;
    color: rgb(69, 59, 46);
    font-weight: 600;
}

.dramIcon {
    margin-right: 5px;
}

.btnContainer {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.seeAllBtn {
    padding: 10px 30px;
    font-size: 20px;
    background-color: transparent;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    transition: background-color .2s;
    border: 1px solid rgb(55, 55, 55);
    color: rgb(55, 55, 55);
}

.seeAllBtn:hover {
    background-color: #f3efef;
}

@media (max-width:1001px) {
    .productsContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}


@media (max-width:749px) {
    .productsContainer {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}

@media (max-width:719px) {
    .allRecentProjectsTitle {
        font-size: 27px !important;
    }
}

@media (max-width:522px) {
    .productImg {
        width: 200px;
        height: 300px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 200px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        margin-bottom: 40px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 15px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 15px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 15px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }

    .seeAllBtn {
        padding: 6px 25px;
        font-size: 19px;
    }
}

@media (max-width:647px) {
    .mainTitle {
        font-size: 37px;
    }
}

@media (max-width:542px) {
    .mainTitle {
        font-size: 35px;
    }
}

@media (max-width:540px) {
    .allRecentProjectsTitle {
        font-size: 25px !important;
    }
}

@media (max-width:539px) {
    .productsContainer {
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width:522px) {
    .productsContainer {
        display: flex;
        justify-content: space-between;
        margin-right: 10px;
        margin-left: 10px;
    }
}

@media (max-width:441px) {
    .productImg {
        width: 170px;
        height: 250px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 170px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 35px;
        margin-bottom: 35px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 14px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 14px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 14px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }

}

@media (max-width:426px) {
    .mainTitle {
        font-size: 33px;
    }
}

@media (max-width:411px) {
    .allRecentProjectsTitle {
        font-size: 23px !important;
    }
}

@media (max-width:381px) {
    .allRecentProjectsTitle {
        font-size: 22.5px !important;
        font-weight: 400 !important;
    }
}


@media (max-width:380px) {
    .productImg {
        width: 160px;
        height: 230px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 160px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 34px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

.product {
    transition: .3s;
}

.imgContainer {
    overflow: hidden;
}

.productImg {
    transition: .3s;
    overflow: hidden;
}

.productImg:hover {
    transform: scale(1.1);
}


@media (max-width:360px) {
    .productImg {
        width: 150px;
        height: 220px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 150px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 33px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }

    .seeAllBtn {
        padding: 5px 24px;
        font-size: 18px;
    }
}

@media (max-width:381px) {
    .allRecentProjectsTitle {
        font-size: 20px !important;
        font-weight: 400 !important;
    }
}


@media (max-width:335px) {
    .productImg {
        width: 140px;
        height: 210px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 140px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        font-size: 32px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:332px) {
    .mainTitle {
        font-size: 32px;
    }
}

@media (max-width:322px) {
    .productsContainer {
        display: flex;
        justify-content: space-between;
        margin-right: 5px;
        margin-left: 5px;
    }
}


@media (max-width:315px) {
    .allRecentProjectsTitle {
        font-size: 18px !important;
        font-weight: 400 !important;
    }
}

@media (max-width:311px) {
    .productsContainer {
        display: flex;
        justify-content: space-between;
    }

    .productImg {
        width: 120px;
        height: 190px;
        object-fit: cover;
    }

    .productTitle {
        max-width: 120px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .productTitle {
        margin-top: 5px;
        font-size: 12px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 12px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

.allRecentProjectsTitle {
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: rgb(55, 55, 55);
    font-weight: 500;
    margin-bottom: 50px;
    letter-spacing: 1px;
}