.section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    overflow: hidden;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1140px;
}

.right {
    display: flex;
    flex-direction: column;
}

.imageContainer {
    position: relative;
}

.textContainer {
    position: absolute;
    bottom: 6%;
}

.leftImgTitle {
    margin-left: 30px;
    color: #fff;
    font-weight: 400;
    opacity: 0.8;
    margin-bottom: 5px;
    font-size: 20px;
}

.data {
    margin-left: 30px;
    color: #fff;
    opacity: 0.8;
    font-weight: 400;

}

.mainTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 40px;
    color: rgb(38, 27, 4);
}

.mainRecent {
    width: 595px;
    height: 460px;
    filter: brightness(55%);
    object-fit: cover;
}

.recentProject {
    position: relative;
}

.recentImg {
    width: 380px;
    height: 220.3px;
    filter: brightness(55%);
    overflow: hidden;
    overflow-wrap: break-word;
    object-fit: cover;
}

.left {
    margin-right: 25px;
}

.right .recentProject:first-child {
    margin-bottom: 17px;
}

@media (max-width:1025px) {
    .mainRecent {
        width: 550px;
        height: 460px;
        filter: brightness(55%);
    }

}

@media (max-width:968px) {
    .mainRecent {
        width: 520px;
        height: 460px;
        filter: brightness(55%);
    }

}

@media (max-width:942px) {
    .mainRecent {
        width: 500px;
        height: 460px;
        filter: brightness(55%);
    }

}

@media (max-width:915px) {
    .mainRecent {
        width: 490px;
        height: 460px;
        filter: brightness(55%);
    }

    .recentImg {
        width: 350px;
        height: 220.3px;
        filter: brightness(55%);
    }
}

@media (max-width:881px) {
    .mainRecent {
        width: 480px;
        height: 460px;
        filter: brightness(55%);
    }

    .recentImg {
        width: 340px;
        height: 220.3px;
        filter: brightness(55%);
    }
}

@media (max-width:867px) {
    .mainRecent {
        width: 480px;
        height: 460px;
        filter: brightness(55%);
    }

    .recentImg {
        width: 340px;
        height: 220.3px;
        filter: brightness(55%);
    }
}

@media (max-width:851px) {
    .mainRecent {
        width: 460px;
        height: 450px;
        filter: brightness(55%);
    }

    .textContainer {
        font-size: 16px;
    }

    .miniRecentTitle {
        font-size: 17px;
    }

    .recentImg {
        width: 320px;
        height: 215.3px;
        filter: brightness(55%);
    }
}

@media (max-width:810px) {
    .mainRecent {
        width: 440px;
        height: 440px;
        filter: brightness(55%);
    }

    .recentImg {
        width: 300px;
        height: 210.3px;
        filter: brightness(55%);
    }
}

@media (max-width:782px) {
    .mainRecent {
        width: 420px;
        height: 430px;
        filter: brightness(55%);
    }

    .recentImg {
        width: 280px;
        height: 205.3px;
        filter: brightness(55%);
    }
}

@media (max-width:743px) {
    .mainRecent {
        width: 400px;
        height: 410px;
        filter: brightness(55%);
    }

    .recentImg {
        width: 260px;
        height: 195.3px;
        filter: brightness(55%);
    }
}

@media (max-width:695px) {
    .container {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .left {
        width: 100%;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }

    .mainRecent {
        width: 100%;
        margin: 0;
    }

    .right {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 0 10px;
    }

    .recentProject {
        width: 45%;
        margin-bottom: 20px;
    }

    .recentImg {
        object-fit: cover;
        width: 100%;
    }

    .textContainer {
        margin: 0;
        padding: 0;
    }

    .miniTextContainer {
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: 10%;
    }

    .mainTitle {
        font-size: 37px;
    }
}

@media (max-width:647px) {
    .mainTitle {
        font-size: 37px;
    }
}

@media (max-width:567px) {
    .miniRecentTitle {
        font-size: 15px;
    }

    .textContainer {
        margin: 0;
        padding: 0;
        font-size: 14px;
    }
}

@media (max-width:516px) {
    .miniRecentTitle {
        font-size: 15px;
        padding: 0;
        margin-left: 5px;
    }

    .miniData {
        margin-left: 5px;
    }

    .textContainer {
        margin: 0;
        padding: 0;
        font-size: 14px;
        margin-left: 0;
    }
}



@media (max-width:542px) {
    .mainTitle {
        font-size: 35px;
    }
}

@media (max-width:462px) {

    .recentImg {
        height: 150px;
        filter: brightness(55%);
    }


    .miniTextContainer {
        position: relative;
        padding: 10px;
        background-color: rgba(55, 55, 55, 0.525);
        border-radius: 5px;
    }

    .miniTextContainer {
        position: relative;
        bottom: auto;
        margin-top: 10px;
    }

    .miniRecentTitle {
        font-size: 13px;
    }

    .mainTitle {
        font-size: 31px;
    }
}

@media (max-width:456px) {
    .mainRecent {
        height: 200px;
        object-fit: cover;
    }
}


@media (max-width:426px) {
    .mainTitle {
        font-size: 33px;
    }
}

@media (max-width:375px) {
    .mainTitle {
        font-size: 30px;
    }
}

@media (max-width:332px) {
    .mainTitle {
        font-size: 32px;
    }
}

@media (max-width:342px) {
    .mainTitle {
        font-size: 28px;
    }
}


@media (max-width:326px) {
    .mainTitle {
        display: flex;
        justify-content: center;
        font-size: 28px;
    }
}

@media (max-width:323px) {
    .mainTitle {
        display: flex;
        justify-content: center;
        font-size: 25px;
    }
}

@media (max-width:300px) {
    .data {
        margin-left: 10px;
    }

    .leftImgTitle {
        margin-left: 10px;
    }
}


.btnContainer {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.seeAllBtn {
    padding: 10px 30px;
    font-size: 20px;
    background-color: transparent;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    transition: background-color .2s;
    border: 1px solid rgb(55, 55, 55);
    color: rgb(55, 55, 55);
}

.seeAllBtn:hover {
    background-color: #f3efef;
}

.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}