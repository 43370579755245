.section {
    margin-bottom: 50px;
    width: 100%;
}

.container {
    display: flex;
    justify-content: space-between;
    margin: 150px 200px 0 200px;
}

.left {
    flex-basis: 50%;
}

.item {
    list-style: none;
}


.right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 50%;
    width: 100%;
    height: 100px;
    position: relative;
}

.productTitle {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: rgb(219, 128, 48);
    font-weight: 600;
    border: 2px solid rgb(219, 128, 48);
    padding: 6px;
    border-radius: 8px;
    margin-bottom: 100px;
}

.productName {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 500;
    color: rgb(55, 55, 55);
    overflow-wrap: break-word;
}

.mainInformation {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 500px;
}

.price {
    display: flex;
    font-size: 25px;
    font-weight: 500;
}

.dollarIconContainer {
    display: flex;
    align-items: center;
}


.colorRowContainer {
    display: inline;
}



.colorRowContainer {
    display: flex;
    align-items: center;
}

.colorsCmpContainer {
    display: flex;
}



.color {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.colorsNameContainer {
    display: flex;
    align-items: center;
}

.colorname {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.colorRowContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.title {
    font-size: 25px;
}

.colorContainer {
    display: flex;
    margin-right: 10px;
    margin-top: 10px;
    flex-basis: calc(33.33% - 10px);
}

.color {
    width: 30px;
    height: 30px;
    margin: 5px;
    transition: .25s;
}

.activeColor {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 30px;
    height: 30px;
    border: 2px solid transparent;
    border-radius: 100px;
    color: white;
}

.activeColor::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 7px;
    top: 3px;
    width: 6px;
    height: 10px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    transform-origin: bottom left;
    transform: rotate(45deg);
    color: white;
}

.availableColors {
    display: flex;
    align-items: center;
    color: rgb(55, 55, 55);
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 20px;
}

.materialCpm {
    color: rgb(55, 55, 55);
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 20px;
}

.list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.item {
    margin: 10px;
}

.material {
    color: rgb(219, 128, 48);
}

.sizeTitle {
    font-size: 15px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    margin: 5px;
    margin-top: 20px;
    opacity: .7;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.sizeContainer {
    display: flex;
    flex-direction: column;
}

.sizes {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.actions {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    margin-top: 30px;
}

.priceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.amd {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    margin-right: 3px;
    margin-left: 5px;
}


.pr {
    color: rgb(55, 55, 55);
    font-size: 30px;
}

.wrapperPrice {
    display: flex;
    flex-direction: column;
}

.priceTitle {
    text-align: center;
    margin: 10px;
    font-size: 18px;
}

.order {
    margin-top: 30px;
}

.secondSection {
    margin-bottom: 100px;
    margin-top: 200px;
}


.containerHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: #fff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 50px;
}

.header {
    width: 90%;
    display: flex;
    justify-content: flex-start;
}

.left {
    font-size: 27px;
    font-weight: 500;
}


.productImg {
    width: 230px;
    height: 320px;
    object-fit: cover;
}

.productsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.product {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.titleProducts {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 40px;
    color: rgb(38, 27, 4);
}

.productname {
    margin-top: 5px;
    font-size: 17px;
    color: rgb(69, 59, 46);
    opacity: 0.9;
    font-weight: 500;
    max-width: 230px;
}

.productType {
    font-size: 17px;
    color: rgb(69, 59, 46);
    opacity: 0.9;
    font-weight: 700;
}

.productPrice {
    margin-top: 5px;
    font-size: 17px;
    color: rgb(69, 59, 46);
    font-weight: 600;
}

.btnContainer {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.seeAllBtn {
    padding: 10px 30px;
    font-size: 20px;
    background-color: transparent;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    transition: background-color .5s;
}

.seeAllBtn:hover {
    background-color: #f3efef;
}

.similarCards {
    display: flex;
    justify-content: center;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
    max-width: 1300px;
    margin: 0 auto;
}

.product {
    margin-right: 100px;
}

.product:last-child {
    margin-right: 0;
}

.filterGroup {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
}

.colorsMaterialContainer {
    display: flex;
    justify-content: space-between;
}

.textAreaContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.description div>p {
    max-width: 900px;
    width: 100%;
}



@media (max-width:1433px) {
    .container {
        margin: 150px 150px 0 150px;
    }

}

@media (max-width:1326px) {
    .container {
        margin: 150px 120px 0 120px;
    }
}

@media (max-width:1260px) {
    .container {
        margin: 150px 100px 0 100px;
    }
}

@media (max-width:1218px) {
    .left {
        flex-basis: 10%;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 50%;
        width: 100%;
        height: 100px;
    }

    .container {
        margin: 150px 150px 0 150px;
    }
}

@media (max-width:1260px) {
    .productsContainer {
        display: flex;
        justify-content: space-evenly;
    }

    .product {
        margin-right: 0;
    }
}

@media (max-width:1221px) {
    .left {
        flex-basis: 10%;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 90%;
        width: 100%;
        height: 100px;
    }

    .container {
        margin: 150px 100px 0 100px;
    }
}

@media (max-width:1173px) {
    .left {
        flex-basis: 10%;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 90%;
        width: 100%;
        height: 100px;
    }

    .container {
        margin: 150px 20px 0 20px;
    }


    .productTitle {
        font-size: 14px;
    }

    .productName {
        font-size: 23px;
    }

    .description {
        font-size: 15px;
    }
}

@media (max-width:1260px) {
    .product {
        margin-right: 0;
    }

    .productTitle {
        margin-bottom: 130px;
    }
}

@media (max-width:1061px) {
    .secondSection {
        margin-top: 170px;
    }
}

@media (max-width:989px) {
    .productTitle {
        font-size: 13px;
    }
}

@media (max-width:962px) {
    .productImg {
        width: 200px;
        height: 300px;
        object-fit: cover;
    }

    .productname {
        max-width: 200px;
    }
}


@media (max-width:986px) {

    .right {
        margin-top: 100px;
        padding-left: 50px;
    }

    .container {
        display: flex;
        flex-direction: column;
        margin-top: 0;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .productTitle {
        margin-bottom: 20px;
    }

    .secondSection {
        margin-top: 100px;
    }
}

.pr {
    display: flex;
    align-items: center;
}

@media (max-width:840px) {

    .productImg {
        width: 170px;
        height: 250px;
        object-fit: cover;
    }

    .productname {
        max-width: 170px;
        font-size: 15px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 14px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 15px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 700;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 15px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:722px) {
    .productImg {
        width: 160px;
        height: 230px;
        object-fit: cover;
    }

    .productname {
        max-width: 160px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:683px) {
    .productImg {
        width: 150px;
        height: 220px;
        object-fit: cover;
    }

    .productname {
        max-width: 150px;
        font-size: 14px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 3px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 700;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width: 832px) {
    .right {
        padding-left: 10px;
    }
}


@media (max-width: 777px) {
    .description div>p {
        max-width: 500px;
        width: 100%;
        overflow: hidden;
    }
}

@media (max-width: 678px) {
    .description div>p {
        max-width: 600px;
        width: 100%;
    }

    .productsContainer {
        display: flex;
        justify-content: space-between;
        margin-left: 10px;
    }
}

@media (max-width:645px) {
    .sizes {
        display: flex;
        flex-direction: column;
    }

    .title {
        font-size: 24px;
    }
}

@media (max-width: 600px) {
    .section {
        margin-bottom: 30px;
    }

    .container {
        display: flex;
        flex-direction: column;
        margin: 50px 30px 0 30px;
    }

    .titleContainer {
        display: flex;
        justify-content: flex-start;
    }

    .left,
    .right {
        flex-basis: 100%;
    }

    .right {
        width: 100%;
        align-items: center;
    }
}

.sizes {
    display: flex;
    justify-content: space-around;
}


@media (max-width:575px) {

    .sizeContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sizes {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .colorsMaterialContainer {
        display: flex;
        flex-direction: column;
    }

    .leftContainer {
        display: flex;
        justify-content: center;
    }

    .rightContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        font-size: 23px;
    }

    .sizeTitle {
        font-size: 15px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        margin: 5px;
        margin-top: 20px;
        opacity: .7;
        width: 100%;
    }
}

@media (max-width:509px) {

    .materialCpm {
        font-size: 19px;
    }

    .availableColors {
        font-size: 19px;
    }
}

@media (max-width:477px) {
    .productsContainer {
        display: flex;
        justify-content: space-around;
    }
}

@media (max-width:430px) {
    .productsContainer {
        display: flex;
        justify-content: center;
        margin-left: 0;
    }

    .product {
        width: 400px;
    }

    .productImg {
        width: 400px;
        height: 320px;
        object-fit: cover;
    }

    .productname {
        max-width: 400px;
    }

    .linkContainerProduct {
        display: flex;
        justify-content: center;
    }

}

@media (max-width:500px) {
    .title {
        font-size: 20px;
    }
}

/* @media (max-width:434px) {
    .materialCpm {
        font-size: 17px;
    }

    .availableColors {
        font-size: 17px;
    }
} */

@media (max-width:485px) {
    .title {
        font-size: 20px;
    }
}

@media (max-width:437px) {
    .pr {
        font-size: 25px;
    }
}

@media (max-width:430px) {
    .productsContainer {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .product {
        width: 380px;
    }

    .productImg {
        width: 390px;
        height: 310px;
        object-fit: cover;
        margin: 0;
        padding: 0;
    }

    .productname {
        max-width: 390px;
    }
}

@media (max-width:421px) {
    .productsContainer {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .product {
        width: 360px;
    }

    .productImg {
        width: 370px;
        height: 300px;
        object-fit: cover;
        margin: 0;
        padding: 0;
    }

    .productname {
        max-width: 370px;
    }

    .productType {
        font-size: 16px;
    }

    .productPrice {
        font-size: 16px;
    }
}

/* @media (max-width:403px) {
    .materialCpm {
        font-size: 16px;
    }
} */

@media (max-width:396px) {
    .productsContainer {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .product {
        width: 330px;
    }

    .productImg {
        width: 350px;
        height: 290px;
        object-fit: cover;
        margin: 0;
        padding: 0;
    }

    .productname {
        max-width: 350px;
    }
}

@media (max-width:386px) {
    .productsContainer {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .product {
        width: 320px;
    }

    .productImg {
        width: 330px;
        height: 260px;
        object-fit: cover;
        margin: 0;
        padding: 0;
    }

    .productname {
        max-width: 330px;
    }
}

@media (max-width:367px) {
    .title {
        font-size: 19px;
    }
}

@media (max-width:360px) {
    .product {
        width: 300px;
    }

    .productImg {
        width: 310px;
        height: 250px;
    }

    .productname {
        max-width: 310px;
    }

    .similarCards {
        margin: 0;
        padding: 0;
    }
}

@media (max-width:330px) {
    .product {
        width: 280px;
    }

    .productImg {
        width: 290px;
        height: 240px;
    }

    .productname {
        max-width: 290px;
    }
}

@media (max-width:321px) {
    .product {
        width: 250px;
    }

    .productImg {
        width: 240px;
        height: 220px;
    }

    .productname {
        max-width: 240px;
    }
}

@media (max-width:310px) {
    .product {
        width: 240px;
    }

    .productImg {
        width: 230px;
        height: 210px;
    }

    .productname {
        max-width: 230px;
    }
}

@media (max-width:284px) {
    .product {
        width: 230px;
    }

    .productImg {
        width: 220px;
        height: 200px;
    }

    .productname {
        max-width: 220px;
    }
}

.right {
    display: flex;
    flex-direction: column;
}

.titleContainer {
    margin-bottom: 30px;
}

.productNameContainer {
    margin-top: 30px;
}

.product {
    overflow: hidden;
    transition: .3s;
}

.productImg {
    transition: .3s;
    overflow: hidden;
}

.productImg:hover {
    transform: scale(1.1);
}

.oldPriceSpan {
    text-decoration-line: line-through;
    font-size: 20px;
    color: crimson;
}

.oldPriceAndDramIcon {
    display: flex;
    align-items: center;
}

.oldDramIcon {
    color: crimson;
    font-size: 20px;
}

.amdCenter {
    display: flex;
    align-items: center;
}