.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
    background: #fcfcfc;
    width: 30px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}

.carousel {
    display: flex;
    flex-direction: column !important;
    margin: 30px;
    max-width: 600px;
}

.carousel .slick-prev {
    left: -10px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

.carousel .slick-next {
    right: -10px;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
}

.slick-prev::before,
.slick-next::before {
    display: none;
}

.custom-indicator {
    bottom: -65px;
}

.custom-indicator li {
    width: 50px;
    height: 50px;
    filter: grayscale(65%);
}

.custom-indicator li.slick-active {
    filter: grayscale(0%);
}

.multi__image:hover {
    transform: scale(1.1);
    transition: 500ms;
}


.sliderImg {
    width: 100%;
    height: 68vh;
    object-fit: contain;
    border-radius: 5px;
}

@media (max-width:986px) {
    .carousel {
        max-width: 820px;
    }

    .sliderImg {
        object-fit: cover;
    }
}

@media (max-width:857px) {
    .carousel {
        max-width: 780px;
    }
}


@media (max-width:810px) {
    .carousel {
        max-width: 750px;
    }
}

@media (max-width:783px) {
    .carousel {
        max-width: 700px;
    }
}

@media (max-width:723px) {
    .carousel {
        max-width: 650px;
    }
}

@media (max-width:667px) {
    .carousel {
        max-width: 600px;
    }
}

@media (max-width:632px) {
    .carousel {
        max-width: 550px;
    }

    .carousel .slick-arrow.slick-prev,
    .carousel .slick-arrow.slick-next {
        display: none;
    }

}

@media (max-width:578px) {
    .carousel {
        max-width: 500px;
    }

    .sliderImg {
        height: 65vh;
    }
}

@media (max-width:525px) {
    .carousel {
        max-width: 450px;
    }

    .sliderImg {
        height: 55vh;
    }
}

@media (max-width:480px) {
    .carousel {
        max-width: 400px;
    }
}

@media (max-width:431px) {
    .carousel {
        max-width: 380px;
    }

}

@media (max-width:429px) {
    .carousel {
        max-width: 370px;
    }
}

@media (max-width:383px) {
    .carousel {
        max-width: 350px;
    }
}

@media (max-width:369px) {
    .carousel {
        max-width: 330px;
    }

    .sliderImg {
        height: 40vh;
    }
}

@media (max-width:335px) {
    .carousel {
        max-width: 310px;
    }

    .sliderImg {
        height: 37vh;
    }
}

@media (max-width:320px) {
    .carousel {
        max-width: 300px;
    }


    .sliderImg {
        height: 35vh;
    }
}

@media (max-width:308px) {
    .carousel {
        max-width: 280px;
    }

    .sliderImg {
        height: 34vh;
    }
}

@media (max-width:285px) {
    .carousel {
        max-width: 100%
    }

    .sliderImg {
        height: 34vh;
    }
}

.dont {
    display: none;
}