.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
}

.container {
    min-height: 500px;
    max-width: 500px;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.title {
    text-align: center;
    margin-top: 30px;
}

.switchsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
}

.switch {
    margin-bottom: 50px;
}

.label {
    margin-right: 20px;
}