.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-color: #f8f8f8;
    padding: 40px;
}

.container {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.content {
    margin: 40px;
    width: 100%;
    max-width: 400px;
}

.cont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
}

.phoneIcon {
    font-size: 24px;
    color: rgb(13, 223, 160);
    margin-right: 16px;
}

.label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    margin-right: 24px;
}

.title {
    font-size: 16px;
    color: #777;
    margin: 0;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .content {
        margin: 24px;
    }
}


.actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 20px;
}

.modal {
    padding: 20px;
}

.btns {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
}


.button {
    width: 170px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    display: inline-block;
}

.button2 {
    color: #fff;
    border: 3px solid #cb1a49;
    background-image: -webkit-linear-gradient(30deg, #cb1a49 50%, transparent 50%);
    background-image: linear-gradient(30deg, #cb1a49 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button2:hover {
    background-position: 100%;
    color: #cb1a49;
}


.button3 {
    color: #fff;
    border: 3px solid #d5d227;
    background-image: -webkit-linear-gradient(30deg, #d5d227 50%, transparent 50%);
    background-image: linear-gradient(30deg, #d5d227 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button3:hover {
    background-position: 100%;
    color: #d5d227;
}


.button4 {
    color: #fff;
    border: 3px solid #1f56b7;
    background-image: -webkit-linear-gradient(30deg, #1f56b7 50%, transparent 50%);
    background-image: linear-gradient(30deg, #1f56b7 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button4:hover {
    background-position: 100%;
    color: #1f56b7;
}

.addBtnContainer {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.desc {
    max-width: 200px;
    margin: 5px;
    inline-size: 500px;
    overflow-wrap: break-word;
}

@media (max-width:491px) {
    .cont {
        display: flex;
        flex-direction: column;
    }

    .label {
        margin-bottom: 20px;
    }

    .button {
        width: 120px;
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 30px;
        cursor: pointer;
        display: inline-block;
    }

    .content {
        margin: 20px;
    }

    .btns {
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        margin-right: 20px;
    }

}

.avatarContainer {
    position: relative;
    width: 110px;
    height: 110px;
}

.avatarImg {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}


.imgArea {
    position: relative;
    width: 400px;
    height: 240px;
    background: var(--grey);
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imgArea .icon {
    font-size: 100px;
}

.imgArea h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 6px;
}

.imgArea p {
    color: #999;
}

.imgArea p span {
    font-weight: 600;
}

.imgArea img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 100;
}

.imgArea::before {
    content: attr(data-img);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    transition: all .3s ease;
    z-index: 200;
}

.imgArea.active:hover::before {
    opacity: 1;
}

.selectImage {
    display: block;
    width: 300px;
    padding: 16px 0;
    border-radius: 15px;
    background: var(--blue);
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
    margin-bottom: 20px;
}

.selectImage:hover {
    background: var(--dark-blue);
}

.mainImgContainer {
    display: flex;
    justify-content: center;
}

.imgButtonContainer {
    display: flex;
    justify-content: center;
}

.sendAddContainerButton {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 25px;
    margin-right: 50px;
}