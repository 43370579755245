*:focus {
    outline: 0;
    outline: none;
}


.slide img {
    max-width: 100%;
    height: auto;
    height: 500px;
    object-fit: contain;
    cursor: pointer;
}

.slide {
    display: flex;
    align-items: center;
    height: 100%;
    transform: scale(.8);
    transition: transform 300ms;
    opacity: 0.9;
    background-color: transparent;
}

.slick-list {
    background-color: transparent !important;
}

.activeSlide {
    transform: scale(1);
    opacity: 1;
}


.slick-slider {
    margin: 0 250px;
    width: 100%;
    height: 100%;
}

.slick-slide:last-of-type {
    margin-right: 0;
}


.sliderWrapper {
    margin: 30px 0;
    width: 800px;
}


@media (max-width:1008px) {
    .slide img {
        max-width: 100%;
        height: auto;
        height: 500px;
        object-fit: contain;
    }

    .sliderWrapper {
        margin: 30px 0;
        width: 800px;
    }

    .activeSlide {
        transform: scale(1);
        opacity: 1;
    }
}

.slick-slide {
    padding: 0 1000px;
    transition: all ease 0.5s;
    opacity: 0.5;
}

.slick-slide.slick-active {
    opacity: 1;
    transform: scale(1.1);
    z-index: 1;
}