.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
}

.container {
    min-height: 500px;
    max-width: 500px;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.titleContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.title {
    margin-top: 30px;
    font-size: 20px;
    color: rgb(55, 55, 55);
}

.clientCountContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 100px;
}

.completedProjectCountContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.button3 {
    color: #fff;
    border: 3px solid #d5d227;
    background-image: -webkit-linear-gradient(30deg, #d5d227 50%, transparent 50%);
    background-image: linear-gradient(30deg, #d5d227 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button3:hover {
    background-position: 100%;
    color: #d5d227;
}

.button {
    width: 110px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;
}

.cc {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 200px;
}

.cp {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 200px;
}

.label {
    font-size: 18px;
    font-weight: 500;
    color: rgb(55, 55, 55);
}

.info {
    color: rgb(55, 55, 55);
}