.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.langContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin-bottom: 20px;
}

.imgContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 700px;
}

.title {
    margin-bottom: 30px;
}

.img {
    height: 300px;
    object-fit: cover;
    min-width: 300px;
}

.description {
    width: 100%;
    max-width: 500px;
    margin: 5px;
}



.actionContainer {
    margin-top: 20px;
}

.button {
    width: 170px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    display: inline-block;
}

.button2 {
    color: #fff;
    border: 3px solid #cb1a49;
    background-image: -webkit-linear-gradient(30deg, #cb1a49 50%, transparent 50%);
    background-image: linear-gradient(30deg, #cb1a49 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button2:hover {
    background-position: 100%;
    color: #cb1a49;
}


.button3 {
    color: #fff;
    border: 3px solid #d5d227;
    background-image: -webkit-linear-gradient(30deg, #d5d227 50%, transparent 50%);
    background-image: linear-gradient(30deg, #d5d227 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button3:hover {
    background-position: 100%;
    color: #d5d227;
}


.button4 {
    color: #fff;
    border: 3px solid #1f56b7;
    background-image: -webkit-linear-gradient(30deg, #1f56b7 50%, transparent 50%);
    background-image: linear-gradient(30deg, #1f56b7 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button4:hover {
    background-position: 100%;
    color: #1f56b7;
}

.abCont {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width:513px) {
    .img {
        height: 250px;
        object-fit: cover;
        min-width: 250px;
    }

    .description {
        padding-left: 10px;
    }
}

@media (max-width:356px) {
    .img {
        height: 200px;
        object-fit: cover;
        min-width: 200px;
    }

    .description {
        margin-left: 40px;
    }
}

@media (max-width:336px) {
    .img {
        height: 200px;
        object-fit: cover;
        min-width: 200px;
    }

    .description {
        margin-left: 10px;
    }

    .actionContainer {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .button {
        width: 100px;
        padding-top: 7px;
        padding-bottom: 7px;
        text-align: center;
        color: #fffdfd;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 30px;
        margin-bottom: 30px;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
    }
}