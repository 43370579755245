.title {
    margin: 50px;
    display: flex;
    justify-content: center;
    color: rgb(55, 55, 55);
}

.container {
    display: flex;
    justify-content: space-around;
}

.sendBtnContainer {
    display: flex;
    justify-content: center;
    margin: 30px;
}

:root {
    --blue: #0071FF;
    --light-blue: #B6DBF6;
    --dark-blue: #005DD1;
    --grey: #f2f2f2;
}



.container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    background: #fff;
    padding: 30px;
    border-radius: 30px;
}

.imgArea {
    position: relative;
    width: 100%;
    height: 240px;
    background: var(--grey);
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imgArea .icon {
    font-size: 100px;
}

.imgArea h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 6px;
}

.imgArea p {
    color: #999;
}

.imgArea p span {
    font-weight: 600;
}

.imgArea img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 100;
}

.imgArea::before {
    content: attr(data-img);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    transition: all .3s ease;
    z-index: 200;
}

.imgArea.active:hover::before {
    opacity: 1;
}

.selectImage {
    display: block;
    width: 100%;
    padding: 16px 0;
    border-radius: 15px;
    background: var(--blue);
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
    margin-bottom: 20px;
}

.selectImage:hover {
    background: var(--dark-blue);
}

.btnContainer {
    display: flex;
    justify-content: center;
}

.imgContainerWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.sendBtnContainer {
    display: flex;
    justify-content: center;
}