.wave {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: -1;
}

.container {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7rem;
    padding: 0 2rem;
}

.img {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.loginContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.img .mainImg {
    width: 600px;
    border-radius: 300px 100px;
}

.form {
    width: 360px;
}

.loginContent img {
    height: 100px;
}

.loginContent h2 {
    margin: 15px 0;
    color: #333;
    text-transform: uppercase;
    font-size: 2.9rem;
}

.loginContent .inputDiv {
    position: relative;
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;
}

.loginContent .inputDiv.one {
    margin-top: 0;
}

.i {
    color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.i .icon {
    transition: .3s;
}

.inputDiv>div {
    position: relative;
    height: 45px;
}

.inputDiv>div>h5 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 18px;
    transition: .3s;
}

.inputDiv:before,
.inputDiv:after {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 0%;
    height: 2px;
    background-color: #38d39f;
    transition: .4s;
}

.inputDiv:before {
    right: 50%;
}

.inputDiv:after {
    left: 50%;
}

.inputDiv.focus:before,
.inputDiv.focus:after {
    width: 50%;
}

.inputDiv.focus>div>h5 {
    top: -5px;
    font-size: 15px;
}

.inputDiv.focus>.i>.icon {
    color: #38d39f;
}

.icon {
    font-size: 24px;
    color: rgb(102, 102, 102);
}

.inputDiv>div>input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    color: #555;
    font-family: 'poppins', sans-serif;
}

.inputDiv.pass {
    margin-bottom: 4px;
}


.btn {
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
    background-size: 200%;
    font-size: 1.2rem;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin: 1rem 0;
    cursor: pointer;
    transition: .5s;
}

.btn:hover {
    background-position: right;
}


@media screen and (max-width: 1050px) {
    .container {
        grid-gap: 5rem;
    }
}

@media screen and (max-width: 1000px) {
    .form {
        width: 290px;
    }

    .login-content h2 {
        font-size: 2.4rem;
        margin: 8px 0;
    }

    .img img {
        width: 400px;
    }
}

@media screen and (max-width: 900px) {
    .container {
        grid-template-columns: 1fr;
        display: flex;
        justify-content: center;
    }

    .img {
        display: none;
    }

    .wave {
        display: none;
    }

    .login-content {
        justify-content: center;
    }
}

.i {
    display: flex;
    align-items: center;
}