.section1 {
    background-color: #fff;
    margin-right: 0;
    margin-bottom: 100px;
    overflow: hidden;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    margin-left: 200px;
}

.left {
    flex: 1;
    padding-right: 50px;
}


.title {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    font-size: 40px;
    font-weight: 500;
    color: rgb(67 57 42);
    margin-bottom: 20px;
}

.desc {
    max-width: 450px;
    font-size: 17px;
    margin-bottom: 40px;
    line-height: 1.5;
    color: rgb(67 57 42);
    width: 500px;
    line-height: 1.5;
    color: rgb(67 57 42);
    max-width: 100%;
    margin-bottom: 40px;
}

.btnsContainer {
    max-width: 300px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.shopNow {
    color: rgb(244, 244, 244);
    background-color: rgb(51, 51, 51);
    padding: 8px 18px;
    font-size: 17px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    outline: none;
    border: none;
    transition: opacity 0.2s;
    margin-right: 10px;
}

.shopNow:hover {
    opacity: 0.8;
}

.order {
    border: 1.5px solid rgb(51, 51, 51);
    padding: 8px 28px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 700;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    color: rgb(51, 51, 51);
    transition: background-color 0.2s;
}

.order:hover {
    background-color: #f3efef;
}

.infoContainer {
    display: flex;
    justify-content: space-around;
    line-height: 2.3rem;
}

.infoItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(51, 51, 51);
}

.infoTitle {
    font-size: 30px;
}

.infoDesc {
    color: rgb(95, 86, 73);
}

.right {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    min-width: 500px;
    flex-shrink: 0;
    flex-grow: 1;
}

.rightContainer {
    flex-shrink: 0;
    width: 100%;
    flex-grow: 0;
}

.rushopNow {
    max-width: 350px;
    font-size: 17px;
}

.section1 {
    background-color: #fff;
    margin-right: 0;
    margin-bottom: 100px;
    overflow: hidden;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1140px;
    margin-left: 200px;
}

.left {
    flex: 1;
    padding-right: 30px;
}

.right {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    min-width: 500px;
    flex-shrink: 0;
    flex-grow: 1;
}

.rightContainer {
    flex-shrink: 0;
    width: 100%;
    flex-grow: 0;
}


@media (max-width: 1199px) {
    .container {
        margin-left: 100px;
    }
}


@media (max-width: 1007px) {
    .infoItem {
        margin-right: 10px !important;
    }
}

@media (max-width: 767px) {
    .container {
        flex-direction: column-reverse;
        margin-left: 0;
        padding: 0;
    }

    .left,
    .right {
        width: 100%;
        margin: 0;
    }

    .left {
        padding-right: 0;
        order: 2;
    }

    .right {
        order: 1;
        justify-content: center;
    }
}


.mainSliderImg {
    width: 100%;
    height: auto;
}

.adaptiveSliderImg {
    width: 100%;
    height: 400px;
    object-fit: cover;
    cursor: pointer;
}


@media only screen and (min-width: 768px) {
    .adaptiveSliderImg {
        height: 500px;
    }

}

@media (max-width: 998px) {
    .infoContainer {
        display: flex;
        justify-content: space-between;
        min-width: 400px;
    }
}

@media (max-width: 991px) {
    .container {
        max-width: 1000px;
    }
}

@media (max-width:768px) {
    .mainContent {
        margin-left: 40px;
    }

    .left {
        margin-right: 0px;
    }

    .infoContainer {
        margin-bottom: 50px;
        display: flex;
        justify-content: space-around;
    }

}

@media (max-width:600px) {
    .infoContainer {
        margin-bottom: 30px;
    }
}

@media (max-width:576px) {
    .infoContainer {
        margin-bottom: 20px;
        margin-left: 10px;
    }
}

@media (max-width:492px) {
    .miniTitle {
        font-size: 23px;
    }
}

@media (max-width:488px) {
    .title {
        font-size: 35px;
    }

    .mainContent {
        margin-left: 20px;
    }

}

@media (max-width:434px) {
    .infoContainer {
        margin-bottom: 10px;
        margin-left: 10px;
    }

    .infoTitle {
        font-size: 27px;
    }
}

@media(max-width:489px) {
    .imgArea {
        width: 300px !important;
        height: 200px !important;
    }
}

@media (max-width:471px) {
    .shopNow {
        padding: 6px 15px;
        font-size: 16px;
    }

    .order {
        padding: 6px 24px;
        font-size: 16px;
    }


    .infoContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        margin-bottom: 30px;
    }

    .infoItem {
        display: flex;
        justify-content: center;
        font-size: 19px;
        padding: 0;
        margin: 0;
        margin-right: 0px !important;
    }

    .miniItem {
        margin: 0 !important;
    }

    .infoTitle {
        font-size: 27px;
    }

    .infoItem:last-child {
        padding-right: 0;
    }

    .btnsContainer {
        margin-right: 20px;
    }
}

@media (max-width:410px) {
    .title {
        font-size: 30px;
    }
}

@media (max-width:400px) {
    .infoItem {
        margin: 0 !important;
        padding: 0 !important;
        margin-right: 30px !important;
    }
}

@media (max-width:383px) {
    .miniItem {
        margin: 0 !important;
        padding: 0 !important;
        margin-right: 23px !important;
    }

    .imgArea {
        width: 280px !important;
        height: 200px !important;
    }
}


@media (max-width:377px) {
    .miniItem {
        margin: 0 !important;
        padding: 0 !important;
        margin-right: 23px !important;
    }
}

@media (max-width:361px) {
    .imgArea {
        width: 260px !important;
        height: 200px !important;
    }
}

@media (max-width:346px) {
    .imgArea {
        width: 240px !important;
        height: 200px !important;
    }
}



@media (max-width:339px) {
    .infoContainer {
        margin-bottom: 0;
    }

    .infoContainer {
        margin-bottom: 30px !important;
    }
}

@media (max-width:338px) {
    .infoItem {
        margin-right: 55px !important;
    }
}

@media (max-width:332px) {
    .infoItem {
        margin-right: 60px !important;
    }
}

@media (max-width:329px) {
    .infoItem {
        margin-right: 65px !important;
    }
}

@media (max-width:326px) {
    .infoItem {
        margin-right: 70px !important;
    }
}

@media (max-width:325px) {
    .imgArea {
        width: 220px !important;
        height: 200px !important;
    }
}

@media (max-width:321px) {
    .infoItem {
        margin-right: 75px !important;
    }
}

@media (max-width:320px) {
    .title {
        font-size: 27px;
    }
}

@media (max-width:317px) {
    .infoItem {
        margin-right: 80px !important;
    }

    .shopNow {
        padding: 4px 12px;
        font-size: 16px;
    }

    .order {
        padding: 4px 21px;
        font-size: 16px;
    }
}

@media (max-width:311px) {
    .infoItem {
        margin-right: 85px !important;
    }
}

@media (max-width:305px) {
    .infoItem {
        margin-right: 90px !important;
    }
}

@media (max-width:303px) {
    .imgArea {
        width: 200px !important;
        height: 200px !important;
    }
}

@media (max-width:302px) {
    .shopNow {
        padding: 4px 12px;
        font-size: 15px;
    }

    .order {
        padding: 4px 21px;
        font-size: 15px;
    }
}

@media (max-width:298px) {
    .infoItem {
        margin-right: 95px !important;
    }
}

@media (max-width:296px) {
    .infoItem {
        margin-right: 100px !important;
    }
}

@media (max-width:288px) {
    .infoItem {
        margin-right: 105px !important;
    }
}

.miniDescription {
    font-size: 16px;
}

.customInput {
    color: rgb(55, 55, 55);
    border-color: rgba(219, 128, 48, 0.5);
}

.customInput:focus {
    border-color: rgb(219, 128, 48);
    box-shadow: 0 0 5px rgba(219, 128, 48, .5);
}

.customInput:hover {
    border-color: rgb(219, 128, 48);
    box-shadow: 0 0 5px rgba(219, 128, 48, .2);
}

.content {
    margin-bottom: 30px;
}

.firstContent {
    margin-top: 10px
}

.label {
    color: rgb(55, 55, 55);
    padding-left: 5px;
    font-size: 15px;
    font-weight: 500;
}

.textField {
    border-color: red !important;
}

.textField:hover {
    border: none !important;
}

.avatarContainer {
    position: relative;
    width: 110px;
    height: 110px;
}

.avatarImg {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}


.imgArea {
    position: relative;
    width: 400px;
    height: 240px;
    background: var(--grey);
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imgArea .icon {
    font-size: 100px;
}

.imgArea h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 6px;
    color: #999;
}

.imgArea p span {
    font-weight: 600;
}

.imgArea img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 100;
}

.imgArea::before {
    content: attr(data-img);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    transition: all .3s ease;
    z-index: 200;
}

.imgArea.active:hover::before {
    opacity: 1;
}

.selectImage {
    display: block;
    width: 300px;
    padding: 16px 0;
    border-radius: 15px;
    background: var(--blue);
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
    margin-bottom: 20px;
}

.selectImage:hover {
    background: var(--dark-blue);
}

.mainImgContainer {
    display: flex;
    justify-content: center;
}

.imgButtonContainer {
    display: flex;
    justify-content: center;
}

.iconImgContainer {
    font-size: 100px;
    color: rgb(55, 55, 55);
}