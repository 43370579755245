.textPrimaryP {
    color: hsl(203, 5%, 66%);
    font-size: 14px;
    font-weight: 700;
}

.fontBold {
    font-weight: 700;
}

.textTitle {
    color: #2e4a66;
}

.textLightblue {
    color: #469cac;
}

.textRed {
    color: #cc3d38;
}

.textYellow {
    color: #a98921;
}

.textGreen {
    color: #3b9668;
}

.container {
    display: grid;
    height: 100vh;
    grid-template-columns: 0.8fr 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
        "sidebar nav nav nav"
        "sidebar main main main";
    grid-gap: 0.2rem;
}

.navbar {
    background: #ffffff;
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 30px 0 30px;
    box-shadow: 0 4px 6px 0 rgb(13, 223, 160);
}


.navIcon {
    display: none;
}

.navIcon>i {
    font-size: 26px;
    color: #a5aaad;
}

.navbarLeft>a {
    margin-right: 30px;
    text-decoration: none;
    color: #a5aaad;
    font-size: 15px;
    font-weight: 700;
}

.navbarLeft .activeLink {
    color: #265acc;
    border-bottom: 3px solid #265acc;
    padding-bottom: 12px;
}



.navbarRight {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarRight>a {
    margin-left: 20px;
    text-decoration: none;
}

.navbarRight>a>.icon {
    color: #a5aaad;
    font-size: 16px;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
    padding: 7px;
}




.sidebar {
    background: #020509;
    grid-area: sidebar;
    overflow-y: auto;
    /* padding: 20px; */
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.sidebarTitle {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(54, 54, 54);
    margin-bottom: 30px;
    background-color: #fff;
    box-shadow: 0 4px 6px 0 rgb(13, 223, 160);
    height: 60px;
}

.sidebarImg {
    display: flex;
    align-items: center;
}

.sidebarTitle>div>img {
    width: 55px;
    object-fit: contain;
}

.sidebarTitle>div>h1 {
    font-size: 18px;
    display: inline;
}

.sidebarTitle>.icon {
    font-size: 18px;
    display: none;
}

.sidebarMenu>h2 {
    color: #3ea175;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 0 10px;
    font-weight: 700;
}

.sidebarLink {
    display: flex;
    color: #f3f4f6;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 5px;
    cursor: pointer;
}

.activeMenuLink {
    background: rgba(62, 161, 117, 0.3);
    color: #3ea175;
}

.activeMenuLink span {
    color: #3ea175 !important;
}

.sidebarLink>a {
    text-decoration: none;
    color: #a5aaad;
    font-weight: 700;
}

.sidebarLink>.icon {
    margin-right: 10px;
    font-size: 18px;
}

.sidebarLogout {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    padding: 10px;
    max-width: 200px;
    color: #e65061;
}

.sidebarLogout>a {
    text-decoration: none;
    color: #e65061;
    font-weight: 700;
    text-transform: uppercase;
}

.sidebarLogout>.icon {
    margin-right: 20px;
    font-size: 18px;
}

.sidebarResponsive {
    display: inline !important;
    z-index: 9999 !important;
    left: 0 !important;
    position: absolute;
}

@media only screen and (max-width: 978px) {
    .container {
        grid-template-columns: 1fr;
        /* grid-template-rows: 0.2fr 2.2fr; */
        grid-template-rows: 0.2fr 3fr;
        grid-template-areas:
            "nav"
            "main";
    }

    .sidebar {
        display: none;
    }

    .sidebarTitle>.icon {
        display: inline;
    }

    .navIcon {
        display: inline;
    }
}

@media only screen and (max-width: 855px) {
    .mainCards {
        grid-template-columns: 1fr;
        gap: 10px;
        margin-bottom: 0;
    }

    .charts {
        grid-template-columns: 1fr;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 480px) {
    .navbarLeft {
        display: none;
    }
}

.menuButtonContainer {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 999;
}


.menuButton {
    cursor: pointer;
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main {
    height: 1500px;
}



.tlt {
    font-size: 20px;
    margin-bottom: 20px;
}

.titleDashboard {
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
    background: white;
    color: #3b9668;
}

.iconDrawer {
    color: #3b9668;
    margin-right: 20px;
}

.main {
    min-height: 1000px;
}

.sidebarLogoutContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    color: #e65061;
}

.sidebarLogoutContainer>a {
    text-decoration: none;
    color: #e65061;
    font-weight: 700;
    text-transform: uppercase;
}


.logoutIconDrawer {
    margin-right: 20px;
}

.logoutIcon {
    font-size: 20px !important;
}

.goToWebsite {
    color: white;
    font-size: 20px;
    margin-right: 10px;
}

.sidebarGoBackWebsite {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    color: #2d23ba;
}

.sidebarGoBackWebsite>a {
    text-decoration: none;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
}

.sidebarGoBackWebsiteContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    color: white;
}

.sidebarGoBackWebsiteContainer>a {
    text-decoration: none;
    color: rgb(30, 7, 7);
    font-weight: 700;
    text-transform: uppercase;
}