* {
    outline: none;
}

.container {
    display: flex;
    justify-content: space-evenly;
}

.filterPanel {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    padding: 20px;
    margin-left: 50px;
    margin-top: 50px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 50px;
    margin-right: 10px;
}

.colorRowContainer {
    margin-bottom: 20px;
}

.photo {
    width: 230px;
    height: 320px;
    overflow: hidden;
}

.productImg {
    transition: transform 0.4s;
}

.photo:hover imgproductImg {
    transform: scale(1.1);
}

.filterButtonContainer {
    display: flex;
    justify-content: center;
}


.filterTitle {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
}

.filterForm {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.filterLabel {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #666;
}

.search {
    margin-left: 6px;
}

.filterCheckbox {
    margin-left: 8px;
}

.filterInput {
    padding: 8px;
    margin-bottom: 10px;
}

.filterPriceRange {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    margin-top: 4px;
}

.filterPriceRange label {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #666;
    cursor: pointer;
}

.inpSearch {
    margin-right: 8px;
}

.filterButton {
    padding: 8px 16px;
    margin-top: 20px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
    background-color: #333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.filterButton:hover {
    background-color: #444;
}

.filterReset {
    margin-top: 12px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #666;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.filterReset {
    color: #333;
    text-decoration: underline;
}


.filterVersion {
    margin-bottom: 8px;
}

.filterVersion select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #333;
    background-color: #fff;
    cursor: pointer;
    outline: none;
}

.filterVersion select:focus {
    border-color: #333;

}

.filterReset {
    margin-top: 12px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #666;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.filterReset:hover {
    color: #333;
    text-decoration: underline;
}

.searchBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
}

.inpSearch[type='text'] {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    border: none;
    background-color: #f0f0f0;
}

.searchIcon {
    position: absolute;
    right: 10px;
    font-size: 1.2rem;
    color: #555;
}

.rangeContainer {
    display: flex;
    justify-content: center;
}

.filterGroupHeader {
    margin: 7px;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    margin-bottom: 100px;
}

.right {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.productImg {
    width: 230px;
    height: 320px;
    object-fit: cover;
}

.productImg {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.productImg:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.productsContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.product {
    display: flex;
    flex-direction: column;
    margin: 10px;
    position: relative;
}

.titleProducts {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 40px;
    color: rgb(38, 27, 4);
}

.productTitle {
    margin-top: 5px;
    font-size: 17px;
    color: rgb(69, 59, 46);
    opacity: 0.9;
    font-weight: 500;
}

.productType {
    font-size: 17px;
    color: rgb(69, 59, 46);
    opacity: 0.9;
    font-weight: 500;
}

.productPrice {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 17px;
    color: rgb(69, 59, 46);
    font-weight: 600;
}

.btnContainer {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.seeAllBtn {
    padding: 10px 30px;
    font-size: 20px;
    background-color: transparent;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    transition: background-color .5s;
}

.seeAllBtn:hover {
    background-color: #f3efef;
}

.productsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 10px;
}

.product {
    display: flex;
    flex-direction: column;
    margin: 10px;
    flex-basis: calc(25% - 50px);
    position: relative;
}

@media (max-width:981px) {
    .productsContainer {
        display: flex;
        justify-content: space-evenly;
        margin: 0 0px;
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .product {
        flex-basis: calc(33.33% - 20px);
    }
}

@media (max-width:603px) {
    .productsContainer {
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media screen and (max-width: 480px) {

    .product {
        flex-basis: calc(50% - 20px);
    }
}

.colorRowContainer {
    display: inline;
}

.colorsNameContainer {
    display: flex;
}

.colorRowContainer {
    display: flex;
    align-items: center;
}

.color {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
}

.colorsNameContainer {
    display: flex;
    align-items: center;
}

.colorname {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.colorRowContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.colorContainer {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.color {
    width: 30px;
    height: 30px;
    margin: 5px;
    cursor: pointer;
    transition: .25s;
}

.activeColor {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 25px;
    height: 25px;
    border: 2px solid transparent;
    border-radius: 100px;
    color: white;
}

.activeColor::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 4.5px;
    top: 1px;
    width: 6px;
    height: 10px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    transform-origin: bottom left;
    transform: rotate(45deg);
    color: white;
}

.left {
    width: 600px;
}

.right {
    flex: 1;
}

@media (min-width:1330px) {
    .productsContainer {
        margin: 0;
    }
}

@media (max-width:1122px) {
    .select {
        margin-top: 30px;
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .left {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .filterPanel {
        min-width: 600px;
        padding: 20px;
        margin: 0;
        margin-bottom: 50px;

    }
}

@media (max-width:660px) {
    .filterPanel {
        min-width: 500px;
    }
}

@media (max-width:570px) {
    .filterPanel {
        min-width: 450px;
    }
}

@media (max-width:522px) {
    .productImg {
        width: 200px;
        height: 300px;
        object-fit: cover;
    }

    .photo {
        width: 200px;
        height: 300px;
        overflow: hidden;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 15px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 15px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 15px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:507px) {
    .filterPanel {
        min-width: 400px;
    }
}

@media(max-width:462px) {
    .productsContainer {
        margin: 0;
    }
}

@media (max-width:455px) {
    .filterPanel {
        min-width: 350px;
    }
}


@media (max-width:441px) {
    .productImg {
        width: 170px;
        height: 250px;
        object-fit: cover;
    }

    .photo {
        width: 170px;
        height: 250px;
        overflow: hidden;
    }


    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 14px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 14px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 14px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:380px) {
    .productImg {
        width: 160px;
        height: 230px;
        object-fit: cover;
    }

    .photo {
        width: 160px;
        height: 230px;
        overflow: hidden;
    }


    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:360px) {
    .productImg {
        width: 150px;
        height: 220px;
        object-fit: cover;
    }

    .photo {
        width: 150px;
        height: 220px;
        overflow: hidden;
    }


    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:341px) {
    .productImg {
        width: 140px;
        height: 210px;
        object-fit: cover;
    }

    .photo {
        width: 140px;
        height: 210px;
        overflow: hidden;
    }


    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:335px) {
    .productImg {
        width: 130px;
        height: 200px;
        object-fit: cover;
    }

    .photo {
        width: 130px;
        height: 200px;
        overflow: hidden;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}



@media (max-width:411px) {
    .filterPanel {
        min-width: 300px;
    }
}


@media (max-width:369px) {
    .filterPanel {
        min-width: 280px;
    }
}

@media (max-width:333px) {
    .filterPanel {
        min-width: 260px;
    }
}

.ribonWrap {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    right: -10px;
}

.ribonWrap::before,
.ribonWrap::after {
    content: '';
    width: 10px;
    height: 10px;
    background: #f2467b;
    position: absolute;
    z-index: -1;
}

.ribonWrap::before {
    top: 0;
    left: 0;
}

.ribonWrap::after {
    bottom: 0;
    right: 0;
}

.ribon {
    width: 225px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    padding: 8px 0;
    background: #ff2166;
    color: #fff;
    position: absolute;
    transform: rotate(45deg);
    left: -17%;
    top: 29%;
    z-index: 9999;
}


.ribonWrapHit {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    left: -10px;
}

.ribonWrapHit::before,
.ribonWrapHit::after {
    content: '';
    width: 10px;
    height: 10px;
    background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
    position: absolute;
    z-index: -1;

}

.ribonWrapHit::before {
    top: 0;
    right: 0;
}

.ribonWrapHit::after {
    bottom: 0;
    left: 0;
}

.ribonHit {
    width: 225px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    padding: 8px 0;
    background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
    color: #fff;
    position: absolute;
    transform: rotate(-45deg);
    right: -17%;
    top: 29%;
}



/* wrap novely */
.ribonWrapNew {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    left: -10px;

}

.ribonWrapNew::before,
.ribonWrapNew::after {
    content: '';
    width: 10px;
    height: 10px;
    background: #4caf50;
    position: absolute;
    z-index: -1;

}

.ribonWrapNew::before {
    top: 0;
    right: 0;
}

.ribonWrapNew::after {
    bottom: 0;
    left: 0;
}

.ribonNew {
    width: 225px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    padding: 8px 0;
    background: #4caf50;
    color: #fff;
    position: absolute;
    transform: rotate(-45deg);
    right: -17%;
    top: 29%;
}

/* top sell */
.ribonTopSell {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    left: -10px;

}

.ribonTopSell::before,
.ribonTopSell::after {
    content: '';
    width: 10px;
    height: 10px;
    background: linear-gradient(#03a9f4 0, #03a9f4 100%);
    position: absolute;
    z-index: -1;

}

.ribonTopSell::before {
    top: 0;
    right: 0;
}

.ribonTopSell::after {
    bottom: 0;
    left: 0;
}

.ribonSell {
    width: 225px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    padding: 8px 0;
    background: linear-gradient(#03a9f4 0, #03a9f4 100%);
    color: #fff;
    position: absolute;
    transform: rotate(-45deg);
    right: -17%;
    top: 29%;
}


@media (max-width:543px) {
    .ribonWrap {
        width: 120px;
        height: 120px;
        overflow: hidden;
        position: absolute;
        top: -10px;
        right: -10px;
    }

    .ribonWrap::before,
    .ribonWrap::after {
        content: '';
        width: 10px;
        height: 10px;
        background: #f2467b;
        position: absolute;
        z-index: -1;
    }

    .ribonWrap::before {
        top: 0;
        left: 0;
    }

    .ribonWrap::after {
        bottom: 0;
        right: 2px !important;
    }

    .ribon {
        width: 225px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        padding: 8px 0;
        background: #ff2166;
        color: #fff;
        position: absolute;
        transform: rotate(45deg);
        left: -30%;
        top: 30%;
        z-index: 9999;
    }


    .ribonWrapHit {
        width: 100px !important;
        height: 100px !important;
        overflow: hidden;
        position: absolute;
        top: 0 !important;
        left: 0 !important;
    }

    .ribonWrapHit::before,
    .ribonWrapHit::after {
        content: "";
        width: 8px !important;
        height: 8px !important;
        background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
        position: absolute !important;
        z-index: -1;
    }

    .ribonWrapHit::before {
        top: 0 !important;
        right: 0 !important;
    }

    .ribonWrapHit::after {
        bottom: 0 !important;
        left: 0 !important;
    }

    .ribonHit {
        width: 150px !important;
        font-size: 12px !important;
        font-weight: 600 !important;
        text-transform: uppercase !important;
        text-align: center !important;
        padding: 6px 0 !important;
        background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
        color: #fff;
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

    .ribonWrapNew {
        width: 100px !important;
        height: 100px !important;
        overflow: hidden !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
    }


    .ribonWrapNew::before,
    .ribonWrapNew::after {
        content: "";
        width: 8px !important;
        height: 8px !important;
        background: #4caf50;
        position: absolute;
        z-index: -1;
    }

    .ribonWrapNew::before {
        top: 0 !important;
        right: 0 !important;
    }

    .ribonWrapNew::after {
        bottom: 0 !important;
        left: 0 !important;
    }

    .ribonNew {
        width: 150px !important;
        font-size: 12px !important;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        padding: 6px 0 !important;
        background: #4caf50;
        color: #fff;
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }

    .ribonTopSell {
        width: 100px !important;
        height: 100px !important;
        overflow: hidden !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
    }


    .ribonTopSell::before,
    .ribonTopSell::after {
        content: "";
        width: 8px !important;
        height: 8px !important;
        background: #55a9f9;
        position: absolute;
        z-index: -1;
    }

    .ribonTopSell::before {
        top: 0 !important;
        right: 0 !important;
    }

    .ribonTopSell::after {
        bottom: 0 !important;
        left: 0 !important;
    }

    .ribonSell {
        width: 150px !important;
        font-size: 12px !important;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        padding: 6px 0 !important;
        background: #55a9f9;
        color: #fff;
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }
}



.pagination {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
    font-size: 1.2rem;
    gap: 5px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pageNum {
    position: relative;
    transition: .2s;
    cursor: pointer;
    margin: 0 10px;
    font-size: 19px;
}

.pageNum:hover {
    background-color: rgba(118, 109, 109, 0.438);
    padding: 5px;
    color: #fff;
}

.activePage {
    background-color: rgb(219, 128, 48);
    padding: 5px 7px;
    color: white;
}

.activePage:hover {
    opacity: .9;
}

.nextLabel {
    display: flex;
    align-items: center;
    font-size: 27px;
    cursor: pointer;
    transition: .2s;
    color: gray;
}

.previousLabel {
    display: flex;
    align-items: center;
    font-size: 27px;
    cursor: pointer;
    transition: .2s;
    color: gray;
}

.previousLabel:hover {
    background-color: rgba(115, 114, 114, 0.228);
}

.nextLabel:hover {
    background-color: rgba(115, 114, 114, 0.228);
}

.containerNotFound {
    display: flex;
    align-items: center;
}

.ico {
    display: flex;
    align-items: center;
}

.iconNotFound {
    display: flex;
    align-items: center;
    font-size: 19px;
}

.nothingFound {
    margin-right: 10px;
    font-size: 19px;
}

.cmp {
    display: flex;
    align-items: flex-start;
    opacity: 0.6;
    margin-bottom: 400px;
}