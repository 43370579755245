.section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    margin-left: 100px;
    overflow: hidden;
}

.container {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    max-width: 1200px;
}

.title {
    margin-bottom: 80px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 40px;
    color: rgb(38, 27, 4);
}


.map {
    width: 600px;
    height: 350px;
    border: none;
}

.left {
    width: 400px;
    height: 500px;
}

.infos {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.address {
    display: flex;
    flex-direction: column;
}

.addressTitle {
    font-size: 18px;
    color: rgb(46, 46, 17);
    margin-bottom: 10px;
}

.addressContent {
    color: rgb(93, 83, 71);
    margin-bottom: 10px;
    font-weight: 400;
}

.officeHourse {
    display: flex;
    flex-direction: column;
}

.officeHourseTitle {
    font-size: 18px;
    color: rgb(46, 46, 17);
    margin-bottom: 10px;
}

.officeHourseContent {
    color: rgb(93, 83, 71);
    margin-bottom: 10px;
    font-weight: 400;
}

.right {
    margin-left: 100px;
}

.socialMediaContent {
    display: flex;
    align-items: center;
}

.socialMediaIcons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 80px;
    cursor: pointer;
}

.socialMediaIconFacebook {
    font-size: 30px;
    color: rgb(66, 66, 66);
}

.socialMediaIconInstagram {
    font-size: 24px;
    color: rgb(66, 66, 66);
}

.socialMediaTitle {
    margin-right: 20px;
    text-transform: uppercase;
    font-size: 22px;
}

.rufollowUs {
    font-size: 19px;
}

@media (max-width:1250px) {
    .map {
        width: 500px;
        height: 350px;
        border: none;
    }
}


@media (max-width:1121px) {
    .map {
        width: 450px;
        height: 350px;
        border: none;
    }
}

@media (max-width:1076px) {
    .map {
        width: 400px;
        height: 350px;
        border: none;
    }

    .right {
        margin-left: 70px;
    }
}

@media (max-width:1004px) {
    .map {
        width: 350px;
        height: 350px;
        border: none;
    }

    .right {
        margin-left: 50px;
    }

    .section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 100px;
        margin-left: 50px;
    }
}

@media (max-width:899px) {}

@media (max-width:888px) {
    .section {
        margin: 0;
        padding: 0;
    }

    .container {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        width: 100%;
    }

    .left {
        width: 100%;
        height: 450px;
    }

    .leftContent {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .address {
        margin-right: 50px;
        margin-left: 30px;
    }

    .addressTitle {
        font-size: 18px;
    }

    .officeHourse {
        margin-right: 30px;
    }

    .officeHourseTitle {
        font-size: 18px;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0;
        margin-bottom: 100px;
    }

    .map {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 1200px;
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
        justify-content: space-evenly;
    }
}

@media (max-width:647px) {
    .title {
        font-size: 37px;
    }
}

@media (max-width:542px) {
    .title {
        font-size: 35px;
    }
}

@media (max-width:426px) {
    .title {
        font-size: 33px;
    }
}


@media (max-width:423px) {
    .addressTitle {
        font-size: 18px;
    }

    .address {
        margin-right: 30px;
        margin-left: 20px;
    }

    .officeHourse {
        margin-right: 0;
    }

    .officeHourseTitle {
        font-size: 18px;
        margin-right: 5px;
    }

    .officeHourseContent {
        font-size: 16px;
    }
}

@media (max-width:380px) {
    .infos {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .address {
        margin: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .officeHourse {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px !important;
    }

    .title {
        margin-bottom: 50px;
    }

    .officeHourseTitle {
        margin-right: 0px !important;
    }

    .socialMediaTitle {
        font-size: 18px;
    }
}

@media (max-width:346px) {
    .addressTitle {
        font-size: 17px;
    }

    .address {
        margin-right: 15px;
        margin-left: 10px;
    }

    .officeHourse {
        margin-right: 0;
    }

    .officeHourseTitle {
        font-size: 17px;
        margin-right: 5px;
    }

    .officeHourseContent {
        font-size: 16px;
    }
}

@media (max-width:332px) {
    .title {
        font-size: 32px;
    }
}