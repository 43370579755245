.section {
    display: flex;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
}

.titleSimilar {
    display: flex;
    font-size: 23px;
    font-weight: 500;
    color: rgb(55, 55, 55);
    margin-left: 10px;
}

.description {
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: rgb(55, 55, 55);
    margin: 20px auto 0;
    width: 1029px;
    text-align: center;
}

.imgContainer {
    margin: 30px auto 54px;
}

.img {
    width: 774px;
    height: 526px;
    object-fit: cover;
}

.imageTopRightPiece {
    font-weight: 600;
    font-size: 20px;
    color: #555;
}

@media (max-width:1257px) {
    .description {
        width: 900px;
    }
}

@media (max-width:855px) {

    .title {
        font-size: 27px !important;
        width: 795px;
    }

    .description {
        font-size: 17px;
        width: 805px;
    }

    .img {
        width: 734px;
        height: 506px;
    }
}

@media (max-width:813px) {
    .title {
        font-size: 25px !important;
        width: 750px;
    }

    .description {
        font-size: 16px;
        width: 760px;
    }

    .img {
        width: 704px;
        height: 496px;
    }
}

@media (max-width:785px) {
    .title {
        font-size: 23px !important;
        width: 670px;
    }

    .description {
        font-size: 16px;
        width: 680px;
    }

    .img {
        width: 670px;
        height: 496px;
    }
}

@media (max-width:699px) {
    .title {
        font-size: 22px !important;
        width: 610px;
    }

    .description {
        font-size: 16px;
        width: 600px;
    }

    .img {
        width: 620px;
        height: 466px;
    }
}

@media (max-width:638px) {
    .title {
        font-size: 22px !important;
        width: 550px;
    }

    .description {
        font-size: 16px;
        width: 560px;
    }

    .img {
        width: 570px;
        height: 456px;
    }
}

@media (max-width:600px) {
    .title {
        font-size: 21px !important;
        width: 500px;
    }

    .description {
        font-size: 16px;
        width: 510px;
    }

    .img {
        width: 550px;
        height: 426px;
    }
}

@media (max-width:570px) {
    .title {
        font-size: 21px !important;
        width: 460px;
    }

    .description {
        font-size: 16px;
        width: 470px;
    }

    .img {
        width: 500px;
        height: 406px;
    }
}

@media (max-width:530px) {
    .title {
        font-size: 21px !important;
        width: 460px;
    }

    .description {
        font-size: 16px;
        width: 470px;
    }

    .img {
        width: 400px;
        height: 366px;
    }
}

@media (max-width:498px) {
    .title {
        font-size: 20px !important;
        width: 420px;
    }

    .description {
        font-size: 16px;
        width: 440px;
    }

    .img {
        width: 400px;
        height: 366px;
    }
}

@media (max-width:452px) {
    .title {
        font-size: 19px !important;
        width: 400px;
    }

    .description {
        font-size: 16px;
        width: 420px;
    }

    .img {
        width: 380px;
        height: 346px;
    }
}

@media (max-width:434px) {
    .title {
        font-size: 18px !important;
        width: 380px;
    }

    .description {
        font-size: 15px;
        width: 400px;
    }

    .img {
        width: 380px;
        height: 346px;
    }
}

@media (max-width:410px) {
    .title {
        font-size: 18px !important;
        width: 350px;
    }

    .description {
        font-size: 15px;
        width: 370px;
    }

    .img {
        width: 100%;
        height: 346px;
    }
}

@media (max-width:395px) {
    .title {
        font-size: 18px !important;
        width: 330px;
    }

    .description {
        font-size: 15px;
        width: 350px;
    }

    .img {
        width: 100%;
        height: 326px;
    }

    .imageTopRightPiece {
        font-weight: 600;
        font-size: 16px;
        color: #555;
    }

}

@media (max-width:377px) {
    .title {
        font-size: 18px !important;
        width: 310px;
    }

    .description {
        font-size: 15px;
        width: 320px;
    }

    .img {
        width: 100%;
        height: 306px;
    }
}

@media (max-width:360px) {
    .title {
        font-size: 17px !important;
        width: 290px;
    }

    .description {
        font-size: 15px;
        width: 300px;
    }

    .img {
        width: 100%;
        height: 286px;
    }
}

@media (max-width:341px) {
    .title {
        font-size: 17px !important;
        width: 260px;
    }

    .description {
        font-size: 15px;
        width: 280px;
    }

    .img {
        width: 100%;
        height: 266px;
    }
}

@media (max-width:320px) {
    .title {
        font-size: 16px !important;
        width: 240px;
    }

    .description {
        font-size: 15px;
        width: 260px;
    }

    .img {
        width: 100%;
        height: 236px;
    }
}


.secondSection {
    margin-bottom: 100px;
    margin-top: 200px;
}


.containerHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #fff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    max-width: 75%;
    margin: 0 auto;
    margin-bottom: 50px;
}

.header {
    display: flex;
    justify-content: flex-start;
}

.left {
    font-size: 27px;
    font-weight: 500;
}


.productImg {
    width: 230px;
    height: 320px;
    object-fit: cover;
}

.productsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.product {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.titleProducts {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 40px;
    color: rgb(38, 27, 4);
}

.productname {
    margin-top: 5px;
    font-size: 17px;
    color: rgb(69, 59, 46);
    opacity: 0.9;
    font-weight: 500;
    max-width: 230px;
}

.productType {
    font-size: 17px;
    color: rgb(69, 59, 46);
    opacity: 0.9;
    font-weight: 700;
}

.productPrice {
    margin-top: 5px;
    font-size: 17px;
    color: rgb(69, 59, 46);
    font-weight: 600;
}

.btnContainer {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.seeAllBtn {
    padding: 10px 30px;
    font-size: 20px;
    background-color: transparent;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    transition: background-color .5s;
}

.linkContainerProduct {
    position: relative;
}

.seeAllBtn:hover {
    background-color: #f3efef;
}

.similarCards {
    display: flex;
    justify-content: center;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
}

.product {
    margin-right: 100px;
}

.product:last-child {
    margin-right: 0;
}

.filterGroup {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.colorsMaterialContainer {
    display: flex;
    justify-content: space-between;
}

.textAreaContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.description div>p {
    max-width: 900px;
    width: 100%;
}

.optionsContainer {
    width: 100%;
}

.options {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.optionsTitle {
    font-weight: 600;
    text-align: left;
    margin-bottom: 4px;
    font-size: 17px;
    line-height: 20px;
    color: rgb(36, 36, 36);
    padding: 8px 10px 8px 10px;
}

.imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media (max-width:1433px) {
    .container {
        margin: 150px 150px 0 150px;
    }

}

@media (max-width:1326px) {
    .container {
        margin: 150px 120px 0 120px;
    }
}

@media (max-width:1260px) {
    .container {
        margin: 150px 100px 0 100px;
    }
}

@media (max-width:1218px) {
    .left {
        flex-basis: 10%;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 50%;
        width: 100%;
        height: 100px;
    }

    .container {
        margin: 150px 150px 0 150px;
    }
}

@media (max-width:1260px) {
    .productsContainer {
        display: flex;
        justify-content: space-evenly;
    }

    .product {
        margin-right: 0;
    }
}

@media (max-width:1221px) {
    .left {
        flex-basis: 10%;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 90%;
        width: 100%;
        height: 100px;
    }

    .container {
        margin: 150px 100px 0 100px;
    }
}

@media (max-width:1173px) {
    .left {
        flex-basis: 10%;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 90%;
        width: 100%;
        height: 100px;
    }

    .container {
        margin: 150px 20px 0 20px;
    }


    .productTitle {
        font-size: 14px;
    }

    .productName {
        font-size: 23px;
    }

    .description {
        font-size: 15px;
    }
}

@media (max-width:1260px) {
    .product {
        margin-right: 0;
    }

    .productTitle {
        margin-bottom: 130px;
    }
}

@media (max-width:1061px) {
    .secondSection {
        margin-top: 170px;
    }
}

@media (max-width:989px) {
    .productTitle {
        font-size: 13px;
    }
}

@media (max-width:962px) {
    .productImg {
        width: 200px;
        height: 300px;
        object-fit: cover;
    }

    .productname {
        max-width: 200px;
    }
}


@media (max-width:986px) {

    .right {
        margin-top: 100px;
        padding-left: 50px;
    }

    .container {
        display: flex;
        flex-direction: column;
        margin-top: 0;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .productTitle {
        margin-bottom: 20px;
    }

    .secondSection {
        margin-top: 100px;
    }
}

.pr {
    display: flex;
    align-items: center;
}

@media (max-width:840px) {

    .productImg {
        width: 170px;
        height: 250px;
        object-fit: cover;
    }

    .productname {
        max-width: 170px;
        font-size: 15px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 14px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 15px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 700;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 15px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:722px) {
    .productImg {
        width: 160px;
        height: 230px;
        object-fit: cover;
    }

    .productname {
        max-width: 160px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 5px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width:683px) {
    .productImg {
        width: 150px;
        height: 220px;
        object-fit: cover;
    }

    .productname {
        max-width: 150px;
        font-size: 14px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin: 3px;
        margin-bottom: 30px;
    }

    .titleProducts {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 40px;
        color: rgb(38, 27, 4);
    }

    .productTitle {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 500;
    }

    .productType {
        font-size: 13px;
        color: rgb(69, 59, 46);
        opacity: 0.9;
        font-weight: 700;
    }

    .productPrice {
        margin-top: 5px;
        font-size: 13px;
        color: rgb(69, 59, 46);
        font-weight: 600;
    }
}

@media (max-width: 832px) {
    .right {
        padding-left: 10px;
    }
}


@media (max-width: 777px) {
    .description div>p {
        max-width: 500px;
        width: 100%;
        overflow: hidden;
    }
}

@media (max-width: 678px) {
    .description div>p {
        max-width: 600px;
        width: 100%;
    }

    .productsContainer {
        display: flex;
        justify-content: space-between;
        margin-left: 10px;
    }
}

@media (max-width:645px) {
    .sizes {
        display: flex;
        flex-direction: column;
    }

    .title {
        font-size: 24px !important;
    }
}

@media (max-width: 600px) {
    .section {
        margin-bottom: 30px;
    }

    .container {
        display: flex;
        flex-direction: column;
        margin: 50px 30px 0 30px;
    }

    .titleContainer {
        display: flex;
        justify-content: flex-start;
    }

    .left,
    .right {
        flex-basis: 100%;
    }

    .right {
        width: 100%;
        align-items: center;
    }
}

.sizes {
    display: flex;
    justify-content: space-around;
}


@media (max-width:575px) {

    .sizeContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sizes {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .colorsMaterialContainer {
        display: flex;
        flex-direction: column;
    }

    .leftContainer {
        display: flex;
        justify-content: center;
    }

    .rightContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        font-size: 23px !important;
    }

    .sizeTitle {
        font-size: 15px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        margin: 5px;
        margin-top: 20px;
        opacity: .7;
        width: 100%;
    }
}

@media (max-width:533px) {
    .titleSimilar {
        font-size: 20px;
    }
}

@media (max-width:509px) {

    .materialCpm {
        font-size: 19px;
    }

    .availableColors {
        font-size: 19px;
    }
}

@media (max-width:477px) {
    .productsContainer {
        display: flex;
        justify-content: space-around;
    }
}


@media (max-width:438) {
    .titleSimilar {
        font-size: 18px;
    }
}

@media (max-width:430px) {
    .productsContainer {
        display: flex;
        justify-content: center;
        margin-left: 0;
    }

    .product {
        width: 400px;
    }

    .productImg {
        width: 400px;
        height: 320px;
        object-fit: cover;
    }

    .productname {
        max-width: 400px;
    }

    .linkContainerProduct {
        display: flex;
        justify-content: center;
    }

}

@media (max-width:500px) {
    .title {
        font-size: 20px !important;
    }
}

/* @media (max-width:434px) {
    .materialCpm {
        font-size: 17px;
    }

    .availableColors {
        font-size: 17px;
    }
} */

@media (max-width:485px) {
    .title {
        font-size: 20px !important;
    }
}

@media (max-width:437px) {
    .pr {
        font-size: 25px;
    }
}

@media (max-width:430px) {
    .productsContainer {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .product {
        width: 380px;
    }

    .productImg {
        width: 390px;
        height: 310px;
        object-fit: cover;
        margin: 0;
        padding: 0;
    }

    .productname {
        max-width: 390px;
    }
}

@media (max-width:421px) {
    .productsContainer {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .product {
        width: 360px;
    }

    .productImg {
        width: 370px;
        height: 300px;
        object-fit: cover;
        margin: 0;
        padding: 0;
    }

    .productname {
        max-width: 370px;
    }

    .productType {
        font-size: 16px;
    }

    .productPrice {
        font-size: 16px;
    }
}



@media (max-width:396px) {
    .productsContainer {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .product {
        width: 330px;
    }

    .productImg {
        width: 350px;
        height: 290px;
        object-fit: cover;
        margin: 0;
        padding: 0;
    }

    .productname {
        max-width: 350px;
    }

}

@media (max-width:438px) {
    .titleSimilar {
        font-size: 18px !important;
    }
}

@media (max-width:386px) {
    .productsContainer {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .product {
        width: 320px;
    }

    .productImg {
        width: 330px;
        height: 260px;
        object-fit: cover;
        margin: 0;
        padding: 0;
    }

    .productname {
        max-width: 330px;
    }
}

@media (max-width:367px) {
    .title {
        font-size: 19px !important;
    }
}


@media (max-width:365px) {
    .titleSimilar {
        font-size: 16px !important;
    }
}

@media (max-width:360px) {
    .product {
        width: 300px;
    }

    .productImg {
        width: 310px;
        height: 250px;
    }

    .productname {
        max-width: 310px;
    }

    .similarCards {
        margin: 0;
        padding: 0;
    }
}

@media (max-width:330px) {
    .product {
        width: 280px;
    }

    .productImg {
        width: 290px;
        height: 240px;
    }

    .productname {
        max-width: 290px;
    }
}

@media (max-width:321px) {
    .product {
        width: 250px;
    }

    .productImg {
        width: 240px;
        height: 220px;
    }

    .productname {
        max-width: 240px;
    }
}

@media (max-width:310px) {
    .product {
        width: 240px;
    }

    .productImg {
        width: 230px;
        height: 210px;
    }

    .productname {
        max-width: 230px;
    }
}

@media (max-width:284px) {
    .product {
        width: 230px;
    }

    .productImg {
        width: 220px;
        height: 200px;
    }

    .productname {
        max-width: 220px;
    }
}

.right {
    display: flex;
    flex-direction: column;
}

.titleContainer {
    margin-bottom: 30px;
}

.productNameContainer {
    margin-top: 30px;
}

.product {
    overflow: hidden;
    transition: .3s;
}

.productImg {
    transition: .3s;
    overflow: hidden;

}

.productImg:hover {
    transform: scale(1.1);
}

.info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
}

.partnerTitle {
    font-size: 17px;
    font-weight: bold;
    color: rgb(55, 55, 55);
    margin-right: 10px;
}

.parntersItem {
    font-size: 16px;
    color: rgb(55, 55, 55);
    font-weight: 500;
}

.title {
    text-align: center;
    font-size: 28px;
}

/* .imageListContainer {
    display: flex;
    justify-content: center;
    margin: 16px 0;
}

.imageListItem {
    margin: 8px;
    break-inside: avoid;
    page-break-inside: avoid;
    position: relative
} */