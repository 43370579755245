.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.icon {
    font-size: 60px;
    color: #90ee90;
    animation: skyIcon alternate 1s ease infinite;
}

.iconContainer {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.text {
    margin: 50px auto;
    color: lightskyblue;
    text-align: center;
}

.linkContainer {
    display: flex;
    justify-content: center;
}


.icon {
    animation: skyIcon .5s alternate infinite ease-in;
}

@keyframes skyIcon {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(20px);
    }
}

.iconContainer {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.linkToDashboard {
    color: #0066b2;
    text-decoration: none;
    position: relative;
    transition: all 0.3s ease;
    font-size: 23px;
    margin-right: 30px;
    font-weight: bold;
}

.linkToDashboard:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #90ee90;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.linkToDashboard:hover {
    opacity: 0.8;
}

.linkToDashboard:hover:after {
    transform: scaleX(1);
}

.linkToDashboard:active:after {
    bottom: -7px;
}

@media (max-width:830px) {
    .text {
        font-size: 23px;
    }

    .icon {
        font-size: 40px;
    }

    .linkToDashboard {
        font-size: 22px;
    }
}

@media (max-width:586px) {
    .text {
        font-size: 21px;
    }

    .icon {
        font-size: 36px;
    }

    .linkToDashboard {
        font-size: 20px;
    }

}

@media (max-width:426px) {
    .text {
        font-size: 19px;
    }

    .icon {
        font-size: 36px;
    }

    .linkToDashboard {
        font-size: 18px;
    }
}

@media (max-width:394px) {
    .text {
        font-size: 17px;
    }

    .icon {
        font-size: 34px;
    }

    .linkToDashboard {
        font-size: 16px;
    }
}