.overlay {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(to top, #2e1753, #1f1746, #131537, #0d1028, #050819);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.text {
    position: absolute;
    top: 10%;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.notFoundTitle {
    font-size: 50px;
}

.star {
    position: absolute;
    width: 2px;
    height: 2px;
    background: #fff;
    right: 0;
    animation: starTwinkle 3s infinite linear;
}

.astronaut img {
    width: 110px;
    position: absolute;
    top: 55%;
    animation: astronautFly 6s infinite linear;
}

.backToHome {
    position: absolute;
    bottom: 200px;

}

.backHome {
    color: white !important;
    background-color: #1f1746 !important;
    padding: 10px !important;
    border-radius: 10px;
    transition: .25s;
    font-size: 20px;
}

.backHome:hover {
    color: #1f1746 !important;
    background-color: #fff !important;
    padding: 10px !important;
}


@keyframes astronautFly {
    0% {
        left: -100px;
    }

    25% {
        top: 50%;
        transform: rotate(30deg);
    }

    50% {
        transform: rotate(45deg);
        top: 55%;
    }

    75% {
        top: 60%;
        transform: rotate(30deg);
    }

    100% {
        left: 110%;
        transform: rotate(45deg);
    }
}

@keyframes starTwinkle {
    0% {
        background: rgba(255, 255, 255, 0.4);
    }

    25% {
        background: rgba(255, 255, 255, 0.8);
    }

    50% {
        background: rgba(255, 255, 255, 1);
    }

    75% {
        background: rgba(255, 255, 255, 0.8);
    }

    100% {
        background: rgba(255, 255, 255, 0.4);
    }
}