.slick-dots li.slick-active button:before {
    color: #ffae0d !important;
}

.slick-dots:hover {
    color: #ff7300 !important;
}

.slick-slide:focus {
    outline: none;
}

.slick-dots li button:before {
    color: rgb(117, 117, 117);
}

.slick-slider {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.slider {
    margin: 10px;
}

.slick-list {
    margin: 0 -10px;
}

@media (max-width:365px) {
    .slider {
        margin: 20px;
    }

    .slick-list {
        margin: 0 -15px;
    }
}

@media (max-width:318px) {
    .slider {
        margin: 25px;
    }

    .slick-list {
        margin: 0 -20px;
    }
}

@media (max-width:309px) {
    .slider {
        margin: 30px;
    }

    .slick-list {
        margin: 0 -20px;
    }
}