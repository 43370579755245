.footer {
    display: flex;
    padding-top: 10px;
    justify-content: center;
    height: 550px;
    background-color: rgb(51, 51, 51);
    width: 100%;
    position: relative;

}

.container {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    padding-top: 100px;
    width: 900px;
}

.left {
    display: flex;
    flex-direction: row;
}

.logoContainer {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    padding: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    height: 80px;
    box-sizing: unset;
}


.menuContainer {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}

.menuTitle {
    display: flex;
    justify-content: center;
    color: rgb(208, 208, 208);
    font-weight: 400;
    text-transform: uppercase;
    font-size: 17px;
    margin-bottom: 40px;
}

.menuItem {
    display: flex;
    justify-content: center;
    color: rgb(200, 200, 200);
    margin-bottom: 20px;
    font-weight: 400;
    cursor: pointer;
}

.address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px;
}

.addressItem {
    color: rgb(200, 200, 200);
    font-weight: 400;
    margin: 10px;
}

.sendContanier {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
}

.sendEmailInp[type='text'] {
    width: 200px;
    padding: 14px;
    border: none;
    background-color: #f0f0f0;
    font-size: 16px;
}

.sendEmailInp[type='text']::placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: rgb(171, 172, 170);
}


.sendEmailInp[type='text'] {
    position: relative;
    width: 200px;
    padding: 14px 40px 14px 14px;
    border: none;
    background-color: #f0f0f0;
    font-size: 16px;
}


.sendIcon {
    position: absolute;
    right: 10px;
    font-size: 1.2rem;
    color: #555;
}

.sendIcon {
    color: rgb(157, 157, 154);
    font-size: 30px;
}

.icon {
    color: rgb(255, 255, 255);
}

.socialMedia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px;
}

.socialMediaItems {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.iconFacebook {
    font-size: 40px;
    margin-right: 10px;
    transition: 0.5s;
}

.iconFacebook:hover {
    transform: rotate(360deg);
    background-color: #3B5998;
    border-radius: 50%;
    padding: 5px;
    color: #fff;
}

.iconInstagram {
    font-size: 45px;
    transition: 0.5s;
}

.iconInstagram:hover {
    transform: rotate(360deg);
    border-radius: 50%;
    padding: 5px;
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
    color: #fff;
}

.footerTitle {
    position: relative;
    bottom: 10px;
}

.right {
    display: flex;
}

.address {
    margin-right: 50px;
}

.socialMedia {
    margin-right: 10px;
}

.allRights {
    display: flex;
    position: absolute;
    bottom: 0;
    margin-bottom: 30px;
}


.iconCopyRight {
    color: #fff;
    font-size: 27px;
}

.copyRight {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.copyRightText {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 300;
}

@media (max-width:734px) {
    .right {
        display: flex;
        flex-direction: column;
    }

    .sendIcon {
        position: absolute;
        right: 25px;
        font-size: 1.5rem;
        color: #555;
    }
}

@media (max-width:734px) {
    .right {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .sendIcon {
        position: absolute;
        right: 70px;
        font-size: 1.5rem;
        color: #555;
    }

    .sendEmailInp[type='text'] {
        width: 160px;
        padding: 14px;
        border: none;
        background-color: #f0f0f0;
        font-size: 16px;
        margin-right: 10px;
    }

    .address {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
}



@media (max-width: 569px) {
    .left {
        display: flex;
        flex-direction: column;
    }

    .logoContainer {
        margin-bottom: 30px;
    }

    .menuTitle {
        margin: 0;
        margin-bottom: 20px;
        margin-left: 10px;
    }

    .logoContainer {
        height: 120px;
        width: 120px;
    }

    .allRights {
        position: absolute;
        bottom: 10px;
        margin-bottom: 0;
    }
}

.sendContanier {
    position: relative;
}

.sendEmailInp {
    width: 100%;
    outline: none;
    margin-right: 10px;
}

.sendIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width:375px) {
    .container {
        display: flex;
        flex-direction: column;
    }


    .left {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .menuContainer {
        margin: 0;
    }

    .menuTitle {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .addressItem {
        margin: 0;
        margin-bottom: 10px;
    }

    .footer {
        height: auto;
    }

    .sendEmailInp[type='text'] {
        position: relative;
        width: 200px;
        padding: 14px 40px 14px 14px;
        border: none;
        background-color: #f0f0f0;
        font-size: 16px;
    }

    .sendIcon {
        position: absolute;
        right: 80px;
        font-size: 1.5rem;
        color: #555;
    }
}

@media (max-width:365px) {
    .iconCopyRight {
        color: #fff;
        font-size: 24px;
    }

    .copyRight {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .copyRightText {
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 300;
        font-size: 15px;
    }
}

@media (max-width:337px) {
    .sendIcon {
        position: absolute;
        right: 75px;
        font-size: 1.5rem;
        color: #555;
    }
}

@media (max-width:329px) {
    .sendIcon {
        position: absolute;
        right: 65px;
        font-size: 1.5rem;
        color: #555;
    }
}

@media (max-width:315px) {
    .sendIcon {
        position: absolute;
        right: 55px;
        font-size: 1.5rem;
        color: #555;
    }
}

@media (max-width:299px) {
    .iconCopyRight {
        color: #fff;
        font-size: 21px;
    }

    .copyRight {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .copyRightText {
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 300;
        font-size: 12px;
    }
}

@media (max-width:295px) {
    .sendIcon {
        position: absolute;
        right: 50px;
        font-size: 1.5rem;
        color: #555;
    }
}

@media (max-width:280px) {
    .sendIcon {
        position: absolute;
        right: 45px;
        font-size: 1.5rem;
        color: #555;
    }
}

.menuItem {
    position: relative;
    align-self: center;
}


.menuItem:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: rgb(219, 128, 48);
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
}

.menuItem:hover:after {
    transform: scaleX(1);
    transform-origin: bottom right;
}

.firstItem {
    margin-top: 10px;
}


.list {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    list-style: none !important;
}

.list .item {
    margin-right: 15px;
    color: rgb(67 57 42);
    cursor: pointer;
    font-size: 16px;
}

.list .item:last-child {
    margin-right: 0;
    color: rgb(67 57 42);
}


.list .item {
    display: inline-block;
    position: relative;
    align-self: center;
}

.list .item:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: rgb(219, 128, 48);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.list .item:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}