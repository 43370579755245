.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.text {
    margin: 40px auto;
    color: crimson;
    text-align: center;
}


.iconContainer {
    display: flex;
    justify-content: center;
}

.icon {
    font-size: 60px;
    color: #0066b2;
    animation: skyIcon 1s alternate infinite ease-in;
}

@keyframes skyIcon {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(20px);
    }
}

.goBack {
    align-self: center;
    font-size: 30px;
    color: #396a90;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.5s;
}

.goBack:hover {
    color: #0963a8;
    font-size: 32px;
}

@media (max-width:830px) {
    .text {
        font-size: 23px;
    }

    .icon {
        font-size: 40px;
    }

    .goBack {
        font-size: 22px;
    }

    .goBack:hover {
        color: #0963a8;
        font-size: 24px;
    }
}

@media (max-width:586px) {
    .text {
        font-size: 21px;
    }

    .icon {
        font-size: 36px;
    }

    .goBack {
        font-size: 20px;
    }

    .goBack:hover {
        color: #0963a8;
        font-size: 22px;
    }
}

@media (max-width:426px) {
    .text {
        font-size: 19px;
    }

    .icon {
        font-size: 36px;
    }

    .goBack {
        font-size: 18px;
    }

    .goBack:hover {
        color: #0963a8;
        font-size: 20px;
    }
}

@media (max-width:394px) {
    .text {
        font-size: 17px;
    }

    .icon {
        font-size: 34px;
    }

    .goBack {
        font-size: 16px;
    }

    .goBack:hover {
        color: #0963a8;
        font-size: 18px;
    }
}