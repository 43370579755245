.section {
    margin: 0 200px;
    display: flex;
    justify-content: center;
    margin-bottom: 155px;
    overflow: hidden;
}

.container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 50px;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px;
    margin-right: 60px;
    margin-left: 20px;
}

.textContent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 200px;
    margin-right: 80px;
}

.title {
    font-size: 32px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.desc {
    max-width: 250px;
    line-height: 2;
    font-size: 18px;
    font-weight: 400;
    color: rgb(59, 59, 59)
}

.img {
    height: 500px;
    object-fit: cover;
    max-width: 600px;
}

.unique {
    font-weight: 600;
    font-size: 30px;
}

.btnContainer {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.btn {
    color: rgb(244, 244, 244);
    background-color: rgb(51, 51, 51);
    padding: 12px 22px;
    font-size: 17px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    outline: none;
    border: none;
    transition: opacity 0.2s;
    font-weight: 500;
}

.btn:hover {
    opacity: .8;
}

@media (max-width:1317px) {
    .section {
        margin: 0 100px;
        margin-bottom: 60px;
    }
}


@media (max-width:995px) {
    .section {
        margin: 0 30px;
        margin-bottom: 90px;
    }

    .left {
        margin-right: 20px;
    }
}

@media (max-width:929px) {
    .title {
        font-size: 33px;
    }

    .desc {
        max-width: 550px;
        font-size: 18px;
    }


    .img {
        height: 500px;
        object-fit: contain;
        min-width: 420px;
    }
}

@media (max-width:810px) {
    .section {
        margin: 0 0 0 20px;
        margin-bottom: 80px;
    }

    .title {
        font-size: 30px;
    }

    .desc {
        max-width: 600px;
        font-size: 17px;
    }

    .img {
        height: 500px;
        object-fit: contain;
        min-width: 390px;
    }

    .container {
        margin-left: 0;
    }
}

@media (max-width:1178px) {
    .section {
        margin: 0 5%;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .left,
    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .left {
        justify-content: center;
    }

    .leftContent {
        width: 100%;
        max-width: 600px;
        margin-right: 0;
        text-align: center;
    }


    .textContent {
        margin: 0;
        padding: 0;
    }

    .right {
        width: 90%;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .textContent {
        margin-top: 30px;
    }

    .title {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .desc {
        max-width: 100%;
        margin-bottom: 30px;
    }

    .img {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
    }

    .btnContainer {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn {
        font-size: 1.2rem;
        padding: 12px 30px;
    }
}

@media(max-width:1175px) {
    .right {
        width: 100%;
        margin-top: 0;
        margin-bottom: 50px;
    }

    .btnContainer {
        margin-top: 0px;
        margin-bottom: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media (max-width:743px) {
    .title {
        font-size: 30px;
        margin-left: 20px;
    }

    .desc {
        max-width: 600px;
        font-size: 17px;
        margin-left: 20px;
    }


    .img {
        height: 500px;
        object-fit: contain;
        min-width: 390px;
    }

    .container {
        margin-left: 0;
    }

    .btn {
        padding: 8px 18px;
        font-size: 17px;
    }

    .left {
        padding: 0;
        margin: 0;
    }
}


@media (max-width:647px) {
    .title {
        font-size: 28px;
    }

    .container {
        margin-left: 0;
    }
}

@media (max-width:600px) {
    .right {
        margin-top: 0 !important;
    }
}

@media (max-width:542px) {
    .title {
        font-size: 27px;
    }

    .container {
        margin-left: 0;
    }

    .desc {
        font-size: 16px;
    }
}

@media (max-width:426px) {
    .title {
        font-size: 26px;
    }

    .container {
        margin-left: 0;
    }
}

@media (max-width: 402px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .left {
        margin-right: 0;
        text-align: center;
    }

    .textContent {
        margin: 0;
        padding: 0;
    }

    .title {
        margin-left: 0;
    }

    .desc {
        max-width: 100%;
        margin-left: 0;
    }

    .img {
        min-width: 100%;
    }

    .btnContainer {
        margin-left: 0 !important;
    }
}


@media (max-width:332px) {
    .title {
        font-size: 25px;
    }

    .container {
        margin-left: 0;
    }

    .desc {
        font-size: 15px;
    }

    .btnContainer {
        margin-left: 0 !important;
    }
}