.faq {
    box-sizing: border-box;
    font-size: 10px;
    max-width: 550px;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(218, 219, 220);
    cursor: pointer;
}


.question {
    display: flex;
    flex-direction: column;
}

.test {
    max-width: 300px;
    display: flex;
    justify-content: space-between;
}

.question h3 {
    font-size: 1.4rem;
    color: rgb(44, 43, 43);
    font-weight: 500;
}

.answer p {
    padding-top: 8px;
    line-height: 1.6;
    font-size: 1rem;
    color: rgb(129, 128, 126);
}

.icon {
    display: flex;
    justify-content: flex-start;
    font-size: 25px;
    transition: transform 0.5s ease-in;
    color: rgb(76, 76, 76);
}

.answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.4s ease;
    animation: fade 2s ease-in-out;
}


.faq.active .answer {
    max-height: 300px;
}

.test {
    min-width: 550px;
    display: flex;
    justify-content: space-between;
}


@keyframes fade {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.mainIcon {
    font-size: 30px;
    margin-bottom: 15px;
    color: rgb(50, 50, 50);
}

.iconBox {
    transition: 1s ease;
}

.iconBox {
    transition: transform 0.5s ease-in-out;
}

.iconBox.active {
    transform: rotate(360deg);
}


.containerMain {
    width: 100%;
}

.faq {
    box-sizing: border-box;
    font-size: 10px;
    min-width: 0%;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(218, 219, 220);
    cursor: pointer;
}

.test {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
}

@media (max-width:1070px) {
    .miniTitle {
        font-size: 1.3rem !important;
    }

    .description {
        font-size: 0.9rem !important;
    }
}

@media (max-width:1010px) {
    .miniTitle {
        font-size: 1.2rem !important;
    }

    .description {
        font-size: 0.8rem !important;
    }
}

@media (max-width:991px) {
    .containerMain {
        margin-top: 50px;
    }
}

@media (max-width:990px) {
    .containerMain {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* @media (max-width:349px) {
    .containerMain {
        margin-top: 100px;
    }
} */

@media (max-width:348px) {
    .mainTitle {
        font-size: 1.15rem !important;
    }

    .description {
        font-size: 0.75rem !important;
    }

    .containerMain {
        margin-top: 50px;
    }
}