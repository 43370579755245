.section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-bottom: 100px;
}

.mainTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 40px;
    color: rgb(38, 27, 4);
}

.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1300px;
    overflow: hidden;
}

.mainImg {
    width: 360px;
    height: 330px;
    object-fit: cover;
}

.left {
    display: flex;
    margin-right: 100px;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 14px;
}

.title {
    max-width: 400px;
    color: rgb(37, 24, 4);
    font-size: 24px;
    font-weight: 500;
}

.description {
    max-width: 400px;
    color: rgb(117, 116, 113);
}

.newPrice {
    display: flex;
    align-items: center;
    font-size: 27px;
    color: crimson;
}

.oldPrice {
    display: flex;
    align-items: center;
    color: rgb(102, 102, 102);
    text-decoration: line-through;
    margin-left: 5px;
}

.img {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 5px;
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
}

.miniProducts {
    position: relative;
    display: flex;
    flex-direction: column;
}

.discaundTag {
    position: absolute;
    top: -5px;
    left: -22px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(206, 31, 31);
    color: white;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
    max-width: 130px;
}

.type {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}

@media (max-width:815px) {
    .mainImg {
        width: 340px;
        height: 310px;
        object-fit: cover;
    }

    .type {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    .img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 5px;
    }

    .description {
        font-size: 15px;
    }
}

@media (max-width:792px) {
    .mainImg {
        width: 330px;
        height: 300px;
        object-fit: cover;
    }

    .type {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    .img {
        width: 115px;
        height: 115px;
        object-fit: cover;
        border-radius: 5px;
    }

    .description {
        min-width: 200px;
        font-size: 14.5px;
    }
}

@media (max-width:779px) {
    .mainImg {
        width: 310px;
        height: 300px;
        object-fit: cover;
    }

    .img {
        width: 110px;
        height: 110px;
        object-fit: cover;
        border-radius: 5px;
    }

    .description {
        font-size: 14px;
    }

    .left {
        display: flex;
        margin-right: 70px;
    }
}

@media (max-width:725px) {
    .mainImg {
        width: 300px;
        height: 290px;
        object-fit: cover;
    }

    .img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 5px;
    }

    .description {
        font-size: 13.5px;
    }

    .title {
        font-size: 27px;
    }

    .left {
        display: flex;
        margin-right: 60px;
    }
}

.saleItemsContainer {
    max-width: 100%;
    padding-top: 5px;
}

@media (max-width:698px) {
    .mainImg {
        width: 290px;
        height: 270px;
        object-fit: cover;
    }

    .img {
        width: 95px;
        height: 95px;
        object-fit: cover;
        border-radius: 5px;
    }

    .description {
        font-size: 13px;
    }

    .title {
        font-size: 27;
    }

    .left {
        display: flex;
        margin-right: 60px;
    }
}

@media (max-width:905px) {
    .container {
        display: flex;
        flex-direction: column;
    }

    .left {
        margin-bottom: 30px;
    }

    .mainImg {
        width: 360px;
        height: 330px;
        object-fit: cover;
    }

    .title {
        font-size: 27px;
    }

    .description {
        font-size: 16px;
    }

    .right {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }


    .img {
        width: 130px;
        height: 130px;
        object-fit: cover;
        border-radius: 5px;
    }

    .name {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .type {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
    }
}

@media (max-width:653px) {
    .left {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        margin-bottom: 30px;
    }
}

@media (max-width:614px) {
    .container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .sliderGrid {
        margin-left: 100px;
    }

    .img {
        width: 450px;
        height: 400px;
        object-fit: cover;
    }

    .name {
        display: flex;
        justify-content: flex-start;
        max-width: 700px;
        margin-left: 10px;
        font-weight: 400;
    }

    .type {
        margin-left: 10px;

    }

    .discaundTag {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 70px;
        height: 50px;
        border-radius: 10% 50% 50% 10%;
        background-color: rgb(206, 31, 31);
        color: white;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

@media (max-width:588px) {
    .sliderGrid {
        margin-left: 90px;
    }
}

@media (max-width:567px) {
    .sliderGrid {
        margin-left: 80px;
    }

}

@media (max-width:550px) {
    .sliderGrid {
        margin-left: 70px;
    }
}

@media (max-width:530px) {
    .sliderGrid {
        margin-left: 60px;
    }
}

@media (max-width:506px) {
    .sliderGrid {
        margin-left: 50px;
    }
}

@media (max-width:495px) {
    .sliderGrid {
        margin-left: 45px;
    }
}

@media (max-width:486px) {
    .sliderGrid {
        margin-left: 40px;
    }

    .mainTitle {
        font-size: 35px;
    }
}

@media (max-width:476px) {
    .sliderGrid {
        margin-left: 35px;
    }

    .mainTitle {
        font-size: 35px;
    }
}

@media (max-width:464px) {
    .sliderGrid {
        margin-left: 30px;
    }

    .mainTitle {
        font-size: 35px;
    }
}

@media (max-width:455px) {
    .sliderGrid {
        margin-left: 25px;
    }

    .mainTitle {
        font-size: 35px;
    }
}

@media (max-width:446px) {
    .sliderGrid {
        margin-left: 23px;
    }

    .mainTitle {
        font-size: 35px;
    }
}

@media screen and (max-width: 431px) {
    .miniProducts img {
        width: 100%;
        object-fit: cover;
    }

    .sliderGrid {
        margin-left: 0;
    }

    .discaundTag {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 80px;
        height: 80px;
        border-radius: 0 50% 50% 50%;
        background-color: rgb(206, 31, 31);
        color: white;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .name {
        margin-left: 20px;
        font-size: 20px;
    }

    .type {
        margin-left: 20px;
        font-size: 18px;
    }

    .mainTitle {
        font-size: 33px;
    }

    .img {
        object-fit: contain;
    }
}


.containerSlider {
    margin-bottom: 50px;

}

.first {
    margin-bottom: 20px;
}

.containerSlider {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.sliderGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    max-width: 1200px;
    width: 100%;
    justify-content: center;
    overflow: hidden;
}

@media (max-width:394px) {
    .name {
        font-size: 18px;
        margin-left: 20px;
    }

    .type {
        font-size: 15px;
        margin-left: 20px;
    }

    .img {
        object-fit: contain;
    }

    .discaundTag {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 90px;
        height: 90px;
        border-radius: 0 50% 50% 50%;
        background-color: rgb(206, 31, 31);
        color: white;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:344px) {
    .name {
        font-size: 17px;
        margin-left: 20px;
        max-width: 300px;
    }

    .type {
        font-size: 14px;
        margin-left: 20px;
    }
}

@media (max-width:305px) {
    .name {
        font-size: 16px;
        margin-left: 30px;
        max-width: 300px;
    }

    .type {
        font-size: 14px;
        margin-left: 30px;
    }

    .img {
        object-fit: contain;
    }
}