.zIndex {
    z-index: 99999 !important;
}


.avatarContainer {
    position: relative;
    width: 110px;
    height: 110px;
}

.avatarImg {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}


.imgArea {
    position: relative;
    width: 400px;
    height: 240px;
    background: var(--grey);
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imgArea .icon {
    font-size: 100px;
}

.imgArea h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 6px;
    color: #999;
}

.imgArea p span {
    font-weight: 600;
}

.imgArea img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 100;
}

.imgArea::before {
    content: attr(data-img);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    transition: all .3s ease;
    z-index: 200;
}

.imgArea.active:hover::before {
    opacity: 1;
}

.selectImage {
    display: block;
    width: 300px;
    padding: 16px 0;
    border-radius: 15px;
    background: var(--blue);
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
    margin-bottom: 20px;
}

.selectImage:hover {
    background: var(--dark-blue);
}

.mainImgContainer {
    display: flex;
    justify-content: center;
}

.imgButtonContainer {
    display: flex;
    justify-content: center;
}

.iconImgContainer {
    font-size: 100px;
    color: rgb(55, 55, 55);
}

.imgContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 360px;
    position: relative;
}

.imgContainer .imgWrapper {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 5px;
    width: 150px;
    height: 150px;
    overflow: hidden;
    transition: 0.3s;
}

.imgContainer .imgWrapper img {
    height: 100px;
    object-fit: cover;
    transition: 0.5s;
}

.imgContainer .imgWrapper:hover img {
    filter: brightness(55%);
}

.imgContainer .imgWrapper .deleteBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
    width: 25px;
    height: 25px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    cursor: pointer;
}

.imgContainer .imgWrapper:hover .deleteBtn {
    display: block;
}

.imgContainer .imgWrapper .deleteBtn::before,
.imgContainer .imgWrapper .deleteBtn::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 2px;
    background-color: #000;
    transform: translate(-50%, -50%) rotate(45deg);
}

.imgContainer .imgWrapper .deleteBtn::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.imgContainer .imgWrapper .deleteBtn:hover {
    background-color: rgba(255, 255, 255, 0.9);
}

.img {
    height: 80px;
}

.imgLabel {
    margin: 10px;
}

.editorContainer {
    margin-bottom: 50px;
}


.imgLabel {
    display: flex;
    align-items: center;
}

.imgIcon {
    margin-right: 10px;
}

.disabledContainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
}

.disabledIcon {
    margin-right: 10px;
    font-size: 20px;
}

.disabledLabel {
    display: flex;
    align-items: center;
}


.imgContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 360px;
}

.imgContainer .imgWrapper {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 5px;
    width: 150px;
    height: 150px;
    overflow: hidden;
    transition: 0.3s;
}

.imgContainer .imgWrapper img {
    height: 100px;
    object-fit: cover;
    transition: 0.5s;
}

.imgContainer .imgWrapper:hover img {
    filter: brightness(55%);
}

.imgContainer .imgWrapper .deleteBtn {
    position: absolute;
    top: 0;
    right: 10%;
    display: none;
    width: 25px;
    height: 25px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    cursor: pointer;
}

.imgContainer .imgWrapper:hover .deleteBtn {
    display: block;
}

.imgContainer .imgWrapper .deleteBtn::before,
.imgContainer .imgWrapper .deleteBtn::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 2px;
    background-color: #000;
    transform: translate(-50%, -50%) rotate(45deg);
}

.imgContainer .imgWrapper .deleteBtn::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.imgContainer .imgWrapper .deleteBtn:hover {
    background-color: rgba(255, 255, 255, 0.9);
}

.img {
    height: 80px;
}

.imgLabel {
    margin: 10px;
}

.editorContainer {
    margin-bottom: 50px;
}


.imgLabel {
    display: flex;
    align-items: center;
}

.tableProductColumn {
    display: flex;
    align-items: center;
}