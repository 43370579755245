.faq {
    box-sizing: border-box;
    font-size: 10px;
    max-width: 550px;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(218, 219, 220);
    cursor: pointer;
}


.question {
    display: flex;
    flex-direction: column;
}

.test {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
}

.question h3 {
    font-size: 1.4rem;
    color: rgb(44, 43, 43);
    font-weight: 500;
}

.answer p {
    padding-top: 8px;
    line-height: 1.6;
    font-size: 1rem;
    color: rgb(129, 128, 126);
}

.icon {
    display: flex;
    justify-content: flex-start;
    font-size: 25px;
    transition: transform 0.5s ease-in;
    color: rgb(76, 76, 76);
}

.answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.4s ease;
    animation: fade 2s ease-in-out;
}


.faq.active .answer {
    max-height: 300px;
}



@keyframes fade {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.mainIcon {
    font-size: 30px;
    margin-bottom: 15px;
    color: rgb(50, 50, 50);
}

.iconBox {
    transition: 1s ease;
}

.iconBox {
    transition: transform 0.5s ease-in-out;
}

.iconBox.active {
    transform: rotate(360deg);
}

@media (max-width:785px) {
    .miniTitle {
        font-size: 18px !important;
    }
}

@media (max-width:663px) {
    .miniTitle {
        font-size: 22px !important;
    }

    .icon {
        display: flex;
        justify-content: flex-end;
        font-size: 25px;
        transition: transform 0.5s ease-in;
        color: rgb(76, 76, 76);
    }

    .test {
        max-width: 550px;
        display: flex;
        justify-content: space-between;
    }
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 50px;
    margin-right: 100px;
}

.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-color: #f8f8f8;
    padding: 40px;
}

.container {
    height: auto;
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.content {
    margin: 40px;
    width: 100%;
    max-width: 400px;
}

.cont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
}

.phoneIcon {
    font-size: 24px;
    color: rgb(13, 223, 160);
    margin-right: 16px;
}

.label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    margin-right: 24px;
}

.title {
    font-size: 16px;
    color: #777;
    margin: 0;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .content {
        margin: 24px;
    }
}


.actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 20px;
}

.modal {
    padding: 20px;
}

.btns {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}


.button {
    width: 110px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;
}

.button2 {
    color: #fff;
    border: 3px solid #cb1a49;
    background-image: -webkit-linear-gradient(30deg, #cb1a49 50%, transparent 50%);
    background-image: linear-gradient(30deg, #cb1a49 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button2:hover {
    background-position: 100%;
    color: #cb1a49;
}


.button3 {
    color: #fff;
    border: 3px solid #d5d227;
    background-image: -webkit-linear-gradient(30deg, #d5d227 50%, transparent 50%);
    background-image: linear-gradient(30deg, #d5d227 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button3:hover {
    background-position: 100%;
    color: #d5d227;
}


.button4 {
    color: #fff;
    border: 3px solid #1f56b7;
    background-image: -webkit-linear-gradient(30deg, #1f56b7 50%, transparent 50%);
    background-image: linear-gradient(30deg, #1f56b7 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button4:hover {
    background-position: 100%;
    color: #1f56b7;
}

.addBtnContainer {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.bottom {
    margin-left: 40px;
}

.faq {
    margin-left: 30px;
}

.container {
    padding-bottom: 10px;
}

.addBtnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    padding-bottom: 20px;
}

.imgArea {
    position: relative;
    width: 100%;
    height: 400px;
    background: var(--grey);
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imgArea .icon {
    font-size: 100px;
}

.imgArea h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 6px;
}

.imgArea p {
    color: #999;
}

.imgArea p span {
    font-weight: 600;
}

.imgArea img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    object-position: center;
    z-index: 100;
}

.uploadImgContainer {
    width: 500px;
}

.imgArea::before {
    content: attr(data-img);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    transition: all .3s ease;
    z-index: 200;
}

.imgArea.active:hover::before {
    opacity: 1;
}

.selectImage {
    display: block;
    width: 100%;
    padding: 16px 0;
    border-radius: 15px;
    background: var(--blue);
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
    margin-bottom: 20px;
}

.selectImage:hover {
    background: var(--dark-blue);
}


.addBtn {
    display: block;
    width: 150px;
    padding: 8px 0;
    border-radius: 15px;
    background: #3ea175;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
}

.addBtn:hover {
    opacity: .8;
}

.img {
    object-fit: cover;
    height: 200px;
    width: 500px;
}


.btnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
}




.editBtn {
    display: block;
    width: 50%;
    padding: 8px 0;
    border-radius: 15px;
    background: #bccf14e2 !important;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
}

.editBtn:hover {
    opacity: .8;
}

.emptyBanersContainer {
    display: flex;
    justify-content: center;
}

.empty {
    display: flex;
    justify-content: center;
    align-content: center;
    min-height: 200px;
}

.container {
    min-height: 800px;
    margin-bottom: 200px;
}

.imgArea {
    margin-top: 100px;
}

@media (max-width:519px) {
    .uploadImgContainer {
        width: 400px;
    }
}

@media (max-width:416px) {
    .uploadImgContainer {
        width: 350px;
    }

    .miniTitle {
        font-size: 17px !important;
    }

    .answer p {
        font-size: 15px;
    }
}

@media (max-width:360px) {
    .uploadImgContainer {
        width: 300px;
    }

    .miniTitle {
        font-size: 15px !important;
    }

    .answer p {
        font-size: 13px;
    }

    .test {
        width: 100%;
    }

    .test {
        max-width: 300px;
        display: flex;
        justify-content: flex-start;
    }

    .mainIcon {
        font-size: 15px !important;
    }
}