.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modalMain {
    position: fixed;
    background-color: white;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    width: 80%;
    max-width: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    animation-name: modal-open;
    animation-duration: 0.5s;
}

@keyframes modal-open {
    from {
        opacity: 0;
        transform: translate(-50%, -60%);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

.displayBlock {
    display: block;
}

.displayNone {
    display: none;
}

.iconContainer {
    position: absolute;
    top: 10px;
    right: 10px;
}

.icon {
    color: crimson;
    transition: transform .3s;
    cursor: pointer;
}

.icon:hover {
    transform: scale(1.2);
}