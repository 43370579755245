.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #f8f8f8;
    padding: 40px;
}

.container {
    height: auto;
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.content {
    margin: 40px;
    width: 100%;
    max-width: 400px;
}

.cont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
}

.phoneIcon {
    font-size: 24px;
    color: rgb(13, 223, 160);
    margin-right: 16px;
}

.label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    margin-right: 24px;
}

.title {
    font-size: 16px;
    color: #777;
    margin: 0;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .content {
        margin: 24px;
    }
}


.actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 20px;
}

.modal {
    padding: 20px;
}

.btns {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
}


.button {
    width: 170px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    display: inline-block;
}

.button2 {
    color: #fff;
    border: 3px solid #cb1a49;
    background-image: -webkit-linear-gradient(30deg, #cb1a49 50%, transparent 50%);
    background-image: linear-gradient(30deg, #cb1a49 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button2:hover {
    background-position: 100%;
    color: #cb1a49;
}


.button3 {
    color: #fff;
    border: 3px solid #d5d227;
    background-image: -webkit-linear-gradient(30deg, #d5d227 50%, transparent 50%);
    background-image: linear-gradient(30deg, #d5d227 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button3:hover {
    background-position: 100%;
    color: #d5d227;
}


.button4 {
    color: #fff;
    border: 3px solid #1f56b7;
    background-image: -webkit-linear-gradient(30deg, #1f56b7 50%, transparent 50%);
    background-image: linear-gradient(30deg, #1f56b7 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.button4:hover {
    background-position: 100%;
    color: #1f56b7;
}

.addBtnContainer {
    display: flex;
    align-items: center;
    margin-top: 40px;
}


.description {
    width: 500px;
    word-break: break-all;
    text-align: left;
}

.content {
    margin-right: 70px;
}

.label {
    display: flex;
}