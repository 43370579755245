.section {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    width: 100%;
    overflow: hidden;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1140px;
    width: 100%;
}

.partners {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.logo {
    height: 40px;
    object-fit: contain;
    font-size: 40px;
    margin-bottom: 13px;
}

.smallLogo {
    width: 50px !important;
    height: auto !important;
}


.title {
    color: rgb(5, 2, 2);
    font-weight: 500;
    margin-bottom: 10px;
}

.desc {
    max-width: 300px;
    color: rgb(123, 124, 123);
    font-size: 18px;
    font-weight: 400;
}

.partner {
    display: flex;
    flex-direction: column;
    margin: 20px;
    position: relative;
}

.mainTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 40px;
    color: rgb(38, 27, 4);
}

.desc {
    max-width: 200px;
    margin: 5px;
    inline-size: 350px;
    overflow-wrap: break-word;
}

@media (max-width:755px) {
    .partners {
        display: flex;
        justify-content: space-between;
        margin: 0 70px;
    }
}

@media (max-width:640px) {
    .partners {
        display: flex;
        justify-content: space-between;
        margin: 0 35px;
    }
}

@media (max-width:572px) {
    .partners {
        display: flex;
        justify-content: space-between;
        margin: 0 20px;
    }
}

@media (max-width:544px) {
    .partners {
        display: flex;
        justify-content: space-between;
        margin: 0 10px;
    }
}

@media (max-width:520px) {
    .partners {
        display: flex;
        justify-content: space-between;
        margin: 0px;
    }
}

@media (max-width:486px) {
    .mainTitle {
        font-size: 35px;
    }
}

@media (max-width:381px) {
    .mainTitle {
        font-size: 32px;
    }
}

.dots {
    background-color: red;
    color: red;
}

@media (max-width:510px) {
    .partners {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .partners {
        display: flex;
        justify-content: space-between;
        margin: 0 40px;
    }


    .partner {
        margin-right: 10px;
    }

    .section {
        display: block;
    }

    .desc {
        max-width: 170px;
    }
}

@media (max-width:383px) {
    .desc {
        font-size: 17px;
    }
}

@media (max-width:372px) {
    .desc {
        font-size: 16px;
    }

    .desc {
        max-width: 150px;
    }
}